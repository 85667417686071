<template>
	<Listbox as="div" v-model="selected" :name="name" class="relative h-[54px]">
	  <ListboxLabel class="block text-xs text-gray-500 font-display font-bold uppercase absolute left-4 -top-1 px-2 bg-white z-10">{{ label }}</ListboxLabel>
	  <div class="relative h-full">
		<ListboxButton class="relative w-full h-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-6 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
		  <span class="block truncate">{{ selected.name }}</span>
		  <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
			<ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
		  </span>
		</ListboxButton>
  
		<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
		  <ListboxOptions class="absolute top-0 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
			<!-- <option value="" disabled selected></option> -->
			<ListboxOption as="template" v-for="option in options" :key="option.value" :value="option" v-slot="{ active, selected }">
			  <li :class="[active ? 'text-blue-700 bg-gray-300' : 'text-blue-900', 'relative cursor-default select-none py-2 pl-6 pr-9']">
				<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ option.name }}</span>
  
				<span v-if="selected" :class="[active ? 'text-white' : 'text-blue-700', 'absolute inset-y-0 right-0 flex items-center pr-4']">
				  <CheckIcon class="h-5 w-5 fill-blue-900" aria-hidden="true" />
				</span>
			  </li>
			</ListboxOption>
		  </ListboxOptions>
		</transition>
	  </div>
	</Listbox>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue'
  import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

  const props = defineProps({
	modelValue: {
		type: Array,
		default: []
	},
	name: {
		type: String,
		required: true
	},
	label: {
		type: String,
		required: false
	},
	name: {
		type: String,
		required: true,
		default: 'state'
	}

})

const emit = defineEmits({
	unvalidatedEvent: null,
	updateValue: (s) => {
		if (s && typeof s === 'string') {
			return true
		} else {
			console.warn(`Invalid submit event payload.`)
			return false
		}
	}
})  

const options = [
	{ value: '', name: 'Select a State' },
	{ value: 'AL', name: 'Alabama' },
	{ value: 'AK', name: 'Alaska' },
	{ value: 'AZ', name: 'Arizona' },
	{ value: 'AR', name: 'Arkansas' },
	{ value: 'CA', name: 'California' },
	{ value: 'CO', name: 'Colorado' },
	{ value: 'CT', name: 'Connecticut' },
	{ value: 'DE', name: 'Delaware' },
	{ value: 'FL', name: 'Florida' },
	{ value: 'GA', name: 'Georgia' },
	{ value: 'HI', name: 'Hawaii' },
	{ value: 'ID', name: 'Idaho' },
	{ value: 'IL', name: 'Illinois' },
	{ value: 'IN', name: 'Indiana' },
	{ value: 'IA', name: 'Iowa' },
	{ value: 'KS', name: 'Kansas' },
	{ value: 'KY', name: 'Kentucky' },
	{ value: 'LA', name: 'Louisiana' },
	{ value: 'ME', name: 'Maine' },
	{ value: 'MD', name: 'Maryland' },
	{ value: 'MA', name: 'Massachusetts' },
	{ value: 'MI', name: 'Michigan' },
	{ value: 'MN', name: 'Minnesota' },
	{ value: 'MS', name: 'Mississippi' },
	{ value: 'MO', name: 'Missouri' },
	{ value: 'MT', name: 'Montana' },
	{ value: 'NE', name: 'Nebraska' },
	{ value: 'NV', name: 'Nevada' },
	{ value: 'NH', name: 'New Hampshire' },
	{ value: 'NJ', name: 'New Jersey' },
	{ value: 'NM', name: 'New Mexico' },
	{ value: 'NY', name: 'New York' },
	{ value: 'NC', name: 'North Carolina' },
	{ value: 'ND', name: 'North Dakota' },
	{ value: 'OH', name: 'Ohio' },
	{ value: 'OK', name: 'Oklahoma' },
	{ value: 'OR', name: 'Oregon' },
	{ value: 'PA', name: 'Pennsylvania' },
	{ value: 'RI', name: 'Rhode Island' },
	{ value: 'SC', name: 'South Carolina' },
	{ value: 'SD', name: 'South Dakota' },
	{ value: 'TN', name: 'Tennessee' },
	{ value: 'TX', name: 'Texas' },
	{ value: 'UT', name: 'Utah' },
	{ value: 'VT', name: 'Vermont' },
	{ value: 'VA', name: 'Virginia' },
	{ value: 'WA', name: 'Washington' },
	{ value: 'WV', name: 'West Virginia' },
	{ value: 'WI', name: 'Wisconsin' },
	{ value: 'WY', name: 'Wyoming' },
]

const selected = ref(options[0])

watch(selected, async (newItem, oldItem) => {
	emit('updateValue', selected.value.value, props.name)
})


  
</script>