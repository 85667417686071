<template>
	<div class="w-full flex items-start content-center">
		<div class="file-upload-area w-full min-h-[170px] flex items-center content-center relative">
			<input type="file" name="" id="" class="absolute top-0 left-0 bottom-0 right-0 opacity-0" @change="handleFileChange($event)">
			<div class="w-full text-center">
				<div class="font-display text-xl uppercase text-gray-500 font-extrabold">Attachments</div>
				<div class="text-gray-500 text-md">Drag & drop files here.</div>
			</div>
			<div v-if="errors.length > 0">
				<div class="mt-3 text-red-600 text-xs" v-for="(error, index) in errors" :key="index">
					<span>{{ error }}</span>
				</div>
			</div>
		</div>
	</div>

	<div class="w-full mt-4">
		<div class="uppercase font-extrabold font-display text-blue-900">{{ file.name }}</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue'

// https://blog.openreplay.com/building-a-custom-file-upload-component-for-vue/
const props = defineProps({
	maxSize: {
		type: Number,
		default: 5,
		required: true
	},
	accept: {
		type: String,
		default: "pdf/*"
	}
})

let errors = reactive([])
let isLoading = ref(false)
let uploadReady = ref(true)

const file = reactive({
	name: "",
	nameOnly: "",
	size: 0,
	type: "",
	extension: "",
	url: "",
	isApprovedType: false,
	isUploaded: false
})

function handleFileChange(e) {
	this.errors = []
	
	// Check if file is selected
	if(e.target.files && e.target.files[0]) {
		if(this.isFileValid(e.target.files[0])) {
			const file = e.target.files[0]
			this.file.name = file.name
			this.file.size = Math.round((file.size / 1024 / 1024) * 100) * 100
			this.file.extension = file.name.split(".").pop()
			this.file.nameOnly = file.name.split(".").shift()
			this.file.isApprovedType = ["pdf","png","jpg","jpeg"].includes(file.extension)
			console.log(this.file.size, this.file.extension, this.file.name, this.file.isApprovedType);
		}
	}
}

function isFileSizeValid(fileSize) {
	if(fileSize > this.maxSize) {
		this.errors(push(`File size should be less than ${this.maxSize}MB`))
		console.warn(`File size is to big`)
	} 
}

function isFileTypeValid(fileExtension) {
	if(!props.accept.split(',').includes(fileExtension)) {
		this.errors.push(`File type should be ${this.accept}`)
		console.warn(`File type not accpeted. Please upload one of the following file types: ${props.accept}`)
	}
}

function isFileValid(file) {
	this.isFileSizeValid(Math.round((file.size / 1024 / 1024) * 100) / 100)
	this.isFileTypeValid(file.name.split(".").pop())
	return this.errors.length === 0 ? true : false
}

</script>

<style scoped>
.file-upload-area {
	/* https://codepen.io/amit_sheen/pen/xxZeyjO */
	--border-color: rgba(150,155,173,.5);
	background-image: repeating-linear-gradient(0deg, var(--border-color), var(--border-color) 10px, transparent 10px, transparent 20px, var(--border-color) 20px), repeating-linear-gradient(90deg, var(--border-color), var(--border-color) 10px, transparent 10px, transparent 20px, var(--border-color) 20px), repeating-linear-gradient(180deg, var(--border-color), var(--border-color) 10px, transparent 10px, transparent 20px, var(--border-color) 20px), repeating-linear-gradient(270deg, var(--border-color), var(--border-color) 10px, transparent 10px, transparent 20px, var(--border-color) 20px);
	background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
	background-position: 0 0, 0 0, 100% 0, 0 100%;
	background-repeat: no-repeat;
}

</style>