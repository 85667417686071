<template>
	<div class="relative pt-8">
		<div class="absolute top-0 left-0 w-full h-28 bg-blue-900"></div>
		<TableDrivers title="Drivers" v-model="store.state.drivers"></TableDrivers>
	</div>	
	<CreateDriver/>
	<RemoveDriver/>
</template>

<script setup>
import TableDrivers from '@/components/TableDrivers.vue'
import CreateDriver from '@/components/CreateDriver.vue'
import RemoveDriver from '@/components/RemoveDriver.vue'
import { inject, onMounted } from 'vue' 

const store = inject('store')



onMounted(() => {
	store.state.drivers = store.methods.getDrivers()
	store.state.pending = store.methods.getPendingChanges()
	store.methods.getDriverCoverages()
})
</script>
