<template>
    <div class="flex justify-center bg-blue-900 pt-10 px-6 relative">
        <div class="absolute w-full bottom-0 bg-gray-100 h-20"></div>
        <div class="dashboard-grid max-w-5xl w-full">
            <div class="font--display text-white text-xl font-bold col-start-1 col-row-1">Welcome<span v-if="store.state.client.contactFirstName">, {{store.state.client.contactFirstName}} {{ store.state.client.contactLastName }}</span></div>
            <router-link class="col-start-1 col-end-2 sm:col-start-2 sm:col-end-3 sm:row-start-3 sm:row-end-4 lg:col-start-1 lg:col-end-2 lg:row-start-2 lg:row-end-3 bg-white shadow-xl p-6 rounded-xl relative" to="/claims" active-class="text-white">
                <div class="absolute right-6 bottom-2 text-gray-300 text-9xl font-bold font-display opacity-50" v-text="store.state.claims.length"></div>
                <div class="relative">
                    <div class="w-6 h-6">
                        <ClaimsIcon />
                    </div>
                    <div class="mt-6">
                        <div class="text-blue-900 font-display text-base">
                            <span class="font-extrabold" v-text="store.state.claims.length"></span> <span class="font-semibold">Reported Claims</span>
                        </div>
                        <p class="text-gray-500 text-sm max-w-[180px]">Create new claims and view statuses of old claims.</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-start-1 col-end-2 sm:col-start-1 sm:col-end-2 sm:row-start-3 sm:row-end-4 lg:col-start-2 lg:col-end-3 lg:row-start-2 lg:row-end-3 bg-white shadow-xl p-6 rounded-xl relative" to="/drivers" active-class="text-white">
                <div class="absolute right-6 bottom-2 text-gray-300 text-9xl font-bold font-display opacity-50" v-text="store.state.drivers.length"></div>
                <div class="relative">
                    <div class="w-6 h-6">
                        <DriversIcon />
                    </div>
                    <div class="mt-6">
                        <div class="text-blue-900 font-display text-base">
                            <span class="font-extrabold" v-text="store.state.drivers.length"></span> <span class="font-semibold">Drivers</span>
                        </div>
                        <p class="text-gray-500 text-sm max-w-[180px]">Add/Remove drivers from your roster.</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-start-1 col-end-2 sm:col-start-1 sm:col-end-2 sm:row-start-4 sm:row-end-5 lg:col-start-1 lg:col-end-2 lg:row-start-3 lg:row-end-4 bg-white shadow-xl p-6 rounded-xl relative" to="/units" active-class="text-white">
                <div class="absolute right-6 bottom-2 text-gray-300 text-9xl font-bold font-display opacity-50" v-text="store.state.units.length"></div>
                <div class="relative">
                    <div class="w-6 h-6">
                        <UnitsIcon />
                    </div>
                    <div class="mt-6">
                        <div class="text-blue-900 font-display text-base">
                            <span class="font-extrabold" v-text="store.state.units.length"></span> <span class="font-semibold">Units</span>
                        </div>
                        <p class="text-gray-500 text-sm max-w-[180px]">Manage your fleet of vehicles and trailers.</p>
                    </div>
                </div>
            </router-link>

            <a href="https://portal.csr24.com/mvc/5875217" class="col-start-1 col-end-2 sm:col-start-2 sm:col-end-3 sm:row-start-4 sm:row-end-5 lg:col-start-2 lg:col-end-3 lg:row-start-3 lg:row-end-4 bg-white shadow-xl p-6 rounded-xl relative" to="/users" active-class="text-white">
                <div class="relative">
                    <div class="w-6 h-6">
                        <CertificatesIcon />
                    </div>
                    <div class="mt-6">
                        <div class="text-blue-900 font-display text-base">
                            <span class="font-semibold">Certificates</span>
                        </div>
                        <p class="text-gray-500 text-sm max-w-[180px]">Easily access and manage your certificates of insurance.</p>
                    </div>
                </div>
            </a>
            
            <div class="col-start-1 col-end-2 sm:col-start-1 row-start-2 row-end-3 sm:col-end-3 sm:row-start-2 sm:row-end-3 lg:col-start-3 lg:col-end-4 lg:row-start-2 lg:row-end-4 bg-white shadow-xl p-6 rounded-xl relative">
                <p class="font-display text-blue-900 text-2xl font-extrabold" v-text="store.state.client.accountName"></p>
                <p class="font-display uppercase text-blue-900 opacity-75 text-sm font-bold">257X7898GP</p>
                <div class="mt-6">
                    <p class="font-display font-bold text-blue-500 uppercase text-xs">Expires On</p>
                    <p class="font-display font-semibold text-blue-900">December 27, 2022</p>
                </div>
                <div class="mt-6 flex flex-col items-start justify-start">
                    <a href="#view-policy" class="btn ghost mb-3">View Policy</a>
                    <a href="#safety-report" class="btn ghost mb-3">Generate Safety Report</a>
                    <a href="#auto-renew" class="btn">Auto Renew</a>
                </div>
            </div>
        </div>
    </div>

    <div class="max-w-5xl mx-auto mt-16 mb-12 px-4 pt-10 pb-12 border-t border-gray-400">
        <div class="sm:flex align-baseline mb-6">
            <p class="font-display font-semibold text-blue-900">Enjoy discounts exclusive to Flywheel customers.</p>
            <router-link class="uppercase font-display font-extrabold text-xs text-blue-500 sm:px-4 ml-auto leading-8" to="/discounts" active-class="text-white">View All</router-link>
        </div>
        <div class="partner-logos">
            <div class="px-4 py-2" v-for="partner in store.state.partners.discounts">
                <a :href="partner.link" target="_blank">
                    <img :src="partner.logo" :alt="partner.name" class="max-h-8 max-w-35">
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted } from 'vue'
import ClaimsIcon from '@/icons/ClaimsOutlineIcon.vue'
import DriversIcon from '@/icons/DriversOutlineIcon.vue'
import UnitsIcon from '@/icons/UnitsOutlineIcon.vue'
import CertificatesIcon from '@/icons/CertificatesOutlineIcon.vue'
// import UsersIcon from '@/icons/UsersIcon.vue'

const store = inject('store')

onMounted(() => {
	store.methods.getDrivers()
	store.methods.getUnits()
})
</script>
