<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="8.113" height="9.272" viewBox="0 0 8.113 9.272">
        <g transform="translate(-1)">
            <path d="M8.636,3.477H7.477V2.318a1.159,1.159,0,1,0-2.318,0V3.477H4V2.318a2.318,2.318,0,0,1,4.636,0Z" transform="translate(-1.262 0)" fill="#1c2427"/>
            <path d="M8.533,7H1.579A.579.579,0,0,0,1,7.579v4.056a.579.579,0,0,0,.579.579H8.533a.579.579,0,0,0,.579-.579V7.579A.579.579,0,0,0,8.533,7ZM5.056,10.477A1.159,1.159,0,1,1,6.215,9.318,1.159,1.159,0,0,1,5.056,10.477Z" transform="translate(0 -2.944)" fill="#1c2427"/>
        </g>
    </svg>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "Locked"
		}
	}
}
</script>