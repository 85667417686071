<template>
	<div class="relative pt-8">
		<div class="absolute top-0 left-0 w-full h-28 bg-blue-900"></div>
		<TableDocuments title="Documents" v-model="store.state.documents"></TableDocuments>
	</div>
	
</template>

<script setup>
import TableDocuments from '@/components/TableDocuments.vue'
import { inject, onMounted } from 'vue'

const store = inject('store')

onMounted(() => {
	store.methods.getDocuments()
})

</script>
