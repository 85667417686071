<template>
	<Navigation></Navigation>
	<router-view />
</template>

<script setup>
import Navigation from '@/components/Navigation.vue'
import Notifications from '@/components/Notifications.vue'
import _ from 'lodash'
import { provide, onMounted } from 'vue'
import store from '@/store'

provide('store', store)

onMounted(() => {
	store.state.pending = store.methods.getPendingChanges()
	store.methods.getPartners()
	store.methods.getClient()
	
})
</script>
<style>
</style>
