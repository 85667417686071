<template>
    <div class="relative pt-8 bg-blue-900 pt-28 lg:pt-32 pb-24 px-10">
        <div class="max-w-5xl m-auto relative">
            <div class="md:max-w-[47.5rem] md:w-1/2 mb-10">
                <p class="uppercase font-display text-yellow-900 text-lg font-semibold tracking-wider" v-html="store.state.partners.overline"></p>
                <p class="text-white font-display text-5xl sm:text-6xl font-extrabold tracking-tight leading[.9em] mb-6 uppercase" v-html="store.state.partners.heading"></p>
                <p class="text-white text-lg leading-normal" v-html="store.state.partners.copy"></p>
            </div>
        </div>
        <figure class="md:absolute md:top-[50%] lg:top-[70%] md:-translate-y-1/2 md:right-0 md:w-1/2 mb-12">
            <img :srcset="store.state.partners.srcset" :src="store.state.partners.src" alt="">
        </figure>
    </div>
    <div class="relative max-w-6xl m-auto px-10 pb-40">
        <div class="-mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div class="rounded-xl bg-white shadow-lg w-full py-12 px-10" v-for="partner in store.state.partners.discounts" :key="partner.id">
                <img :src="partner.logo" :alt="partner.name" class="h-8 mb-5 max-w-full">
                <p class="text-base leading-snug text-gray-500" v-html="partner.description"></p>
                <a :href="partner.link" class="btn mt-8 inline-block" target="_blank">Claim Discount</a>
            </div>
        </div>
    </div>
	
</template>

<script setup>
import { inject, onMounted } from 'vue'

const store = inject('store')

onMounted(() => {
	store.methods.getPartners()
})

</script>
