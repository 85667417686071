<template>
	<div class="relative">
		<label :for="name" class="block text-xs text-gray-500 font-display font-bold uppercase absolute left-4 -top-1 px-2 bg-white" v-text="label"></label>
		<div class="mt-1">
			<textarea @input="handleUpdate" :rows="rows" :name="name" :id="name"
				class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
			<span class="input-optional font-display font-extrabold text-xs uppercase text-gray-300 absolute right-6 top-3 pointer-events-none" v-if="!props.required">Optional </span>
		</div>
	</div>
</template>

<script setup>

	const props = defineProps({
		name: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: false
		},
		rows: {
			type: String,
			required: false,
			default: '4'
		}
	})

	const emit = defineEmits({
		unvalidatedEvent: null,
		updateValue: (s) => {
			if (s && typeof s === 'string') {
				return true
			} else {
				console.warn(`Invalid submit event payload.`)
				return false
			}
		}
	})

	const handleUpdate = (event) => {
		emit('updateValue', event.target.value, props.name)
	}

</script>