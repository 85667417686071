<template><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><title>{{title}}</title><g fill="#697884"><path d="M15,3H12V1a1,1,0,0,0-1-1H5A1,1,0,0,0,4,1V3H1A1,1,0,0,0,1,5H15a1,1,0,0,0,0-2ZM6,3V2h4V3Z"></path><path d="M2.055,7l.45,8.1a1,1,0,0,0,1,.9h9a1,1,0,0,0,.995-.9l.45-8.1Z" fill="#697884"></path> </g></svg></template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "Delete"
		}
	}
}
</script>