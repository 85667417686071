<template>
	<TransitionRoot as="template" :show="!store.methods.isObjEmpty(store.state.removeUnit)">
		<Dialog as="div" class="relative z-10" @close="store.methods.closeRemoveUnit()">
			<TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
				enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>
			<div class="fixed inset-0" />
			<div class="fixed inset-3 overflow-hidden">
				<div class="absolute inset-0 overflow-hidden">
					<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
						<TransitionChild as="template"
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enter-from="translate-x-full" enter-to="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leave-from="translate-x-0" leave-to="translate-x-full">
							<DialogPanel class="pointer-events-auto w-screen max-w-xl p-6">
								<form class="flex flex-col divide-y divide-gray-500 bg-white shadow-2xl rounded-xl" :class="{submitting: store.state.removingUnit}">
									<div class="h-0 flex-1 overflow-y-scroll">
										<div class="bg-blue-900 py-6 px-4 sm:px-6 rounded-t-xl">
											<div class="flex items-center justify-between">
												<DialogTitle class="text-2xl font-display uppercase font-extrabold text-white">Remove Unit</DialogTitle>
												<div class="ml-3 flex h-7 items-center">
													<button type="button"
														class="rounded-md text-center bg-blue-900 hover:bg-blue-700 text-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-white"
														@click="store.methods.closeRemoveUnit()">
														<span class="sr-only">Close panel</span>
														<XMarkIcon class="h-6 w-6" aria-hidden="true" />
													</button>
												</div>
											</div>
										</div>
										<div class="flex flex-1 flex-col justify-between">
											<div class="divide-y divide-gray-400 px-4 sm:px-6 pb-6">
												<div class="space-y-1 py-8">
													<div class="font-display font-extrabold text-2xl text-blue-900 uppercase">{{ `${store.state.removeUnit.year} ${store.state.removeUnit.make} ${store.state.removeUnit.model}` }}</div>
													<div class="flex font-display text-xs uppercase font-extrabold">
                                                        <div class="flex">
                                                            <div class="text-gray-500 pr-2">VIN</div>
                                                            <div class="text-blue-900 font-bold">{{ store.state.removeUnit.vin }}</div>
                                                        </div>
                                                    </div>
												</div>
												<div class="mr-auto w-full pr-4" :class="{errors: v$.effectiveDate.$error}">
													<Date name="effectiveDate" label="Change Effective Date" @update-value="updateValue" />
													<div v-for="error in v$.effectiveDate.$errors" class="error">{{ error.$message }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="flex flex-shrink-0 justify-end items-end px-4 py-4">
										<div class="flex">
											<button type="submit" class="btn mx-1" @click.prevent="validateUnit()">Request Removal</button>
										</div>
									</div>
								</form>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
  
<script setup>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { inject, reactive, computed } from 'vue'
	import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
	import { XMarkIcon } from '@heroicons/vue/24/outline'
	import Date from '@/components/inputs/Date.vue'

	// Pull in the global store.
	const store = inject('store')
	// Locally hold the new unit data.
	const unit = reactive({effectiveDate: ''})	

	// Validation Rules
	const futureDate = (value) => {
		return value > store.state.currentDate
	}
    
	const rules = computed(() => {
		return {
			effectiveDate: { 
				required,
				futureDate: helpers.withMessage('The date must be in the future.', futureDate)
			}
		}	
    })
	const v$ = useVuelidate(rules, unit)

	// Validate the unit information and then
	// store it and send it to Swagger API
	async function validateUnit(){
		const valid = await v$.value.$validate()
		if(valid) store.methods.removeUnit(store.state.removeUnit.id, unit.effectiveDate.toISOString())
	}

	// Update the value in the local unit variable.
	function updateValue(value, property) {
		// console.log(property, value)
		unit[property] = value
	}
</script>