<template><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="classList"><title>{{title}}</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" :stroke="color" stroke-miterlimit="10"><line :stroke="color" x1="15" y1="11" x2="19" y2="11"></line> <line :stroke="color" x1="15" y1="15" x2="19" y2="15"></line> <path d="M4,17c0-1.657,1.343-3,3-3h2c1.657,0,3,1.343,3,3H4z" stroke-linejoin="miter" stroke="none" :fill="color"></path> <path d="M19,3c0,1.105-0.895,2-2,2 s-2-0.895-2-2H9c0,1.105-0.895,2-2,2S5,4.105,5,3H1v18h22V3H19z"></path> <circle cx="8" cy="11" r="2" stroke-linejoin="miter" stroke="none" :fill="color"></circle></g></svg></template>

<script>
export default {
	props: {
        classList: {
            type: String
        },
        color: {
            type: String,
            default: "#1C2427"
        },
		title: {
			type: String,
			default: "Drivers"
		}
	}
}
</script>