<template>
	<div class="relative pt-8">
		<div class="absolute top-0 left-0 w-full h-28 bg-blue-900"></div>
		<TableUI title="Claims" v-model="store.state.claims"></TableUI>
	</div>
	
</template>

<script setup>
import { inject } from 'vue'
import TableUI from '@/components/TableUI.vue'

const store = inject('store')
</script>
