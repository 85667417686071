import { DialogDescription } from "@headlessui/vue"
import { _ } from "core-js"
import { reactive, computed } from "vue"

const state = reactive({
	clientId: 110054,
	client: {},
	drivers: [],
	driverCoverages: [],
	unitCoverages: [],
	policies: [],
	units: [],
	sidePanelOpen: false,
	addDriverOpen: false,
	removeDriverOpen: false,

	removeDriver: {},
	updateUnit: {},
	removeUnit: {},
	pending: [],
	fakeRows: [1,2,3],
	claims: [
		{
			id: 'RV1800100',
			driver: 'Harley Neil',
			amount: '$2,855.00',
			status: 'In Review',
			date: '10/01/2022'
		},
		{
			id: 'GZ18563987',
			driver: 'Cody Bob',
			amount: '$585.87',
			status: 'Pending',
			date: '08/23/2022'
		},
		{
			id: 'BP9657485',
			driver: 'Brian Bowler',
			amount: '$2,855.00',
			status: 'In Review',
			date: '10/01/2022'
		},
		{
			id: 'GZ1856398',
			driver: 'Cody Bob',
			amount: '$3,857.63',
			status: 'Approved',
			date: '12/20/2021'
		},
		{
			id: 'MV8569745',
			driver: 'Aden Levy',
			amount: '$1,569.00',
			status: 'Approved',
			date: '07/06/2021'
		},
		{
			id: 'TB5689173',
			driver: 'Claudia Hagan',
			amount: '$15,895.00',
			status: 'Denied',
			date: '01/13/2021'
		}
	],
	documents: [],
	partners: [],
	currentDate: new Date(),
	addingDriver:  false
})
const swagger_url = 'https://flywheel.giganticdesign.com/swagger'
const methods = {
	today() { return new Date(); },
	isDateInFuture(date) {
		var today = new Date();
		return date >= today;
	},
	isObjEmpty(obj) { return JSON.stringify(obj) === '{}' },

	openSidePanel() { state.sidePanelOpen = true },
	closeSidePanel() { state.sidePanelOpen = false },
	loadRemoveDriver(driver){ state.removeDriver = driver },
	closeRemoveDriver() { state.removeDriver = {} },
	loadUpdateUnit(unit){ state.updateUnit = unit },
	closeUpdateUnit() { state.updateUnit = {} },
	loadRemoveUnit(unit){ state.removeUnit = unit },
	closeRemoveUnit() { state.removeUnit = {} },

	getPartners() {
		fetch('https://goflywheel.com/partners/json', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', }
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Partner Discounts:', data)
			state.partners = data
		})
		.catch((error) => {
			console.error('Error:', error)
		})
	},	

	async getPendingChanges(offset = 0, limit = 50) {
		const token = await methods.fetchToken()
	
		const data = { 
			clientId: state.clientId,
			token: token
		}
	
		return fetch(`${swagger_url}/get-pending.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Pending:', data)
			state.pending = data;
			
			// return data;
		})
		.catch((error) => { console.error('Error:', error) })
	},

	async getClient() {
		const token = await methods.fetchToken()
	
		const data = {
			clientId: state.clientId,
			token: token
		}
	
		return fetch(`${swagger_url}/get-client.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Client:', data)
			state.client = data;
		})
		.catch((error) => { console.error('Client Error:', error) })
	},

	async getDrivers(offset = 0, limit = 50) {
		const token = await methods.fetchToken()
	
		const data = { 
			skip: offset,
			take: limit,
			sortcolumnname: "string",
			isdescending: true,
			clientId: state.clientId,
			token: token
		}
	
		return fetch(`${swagger_url}/get-drivers.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Drivers:', data)
			state.drivers = data;
			// return data;
		})
		.catch((error) => { console.error('Error:', error) })
	},

	async getDriverCoverages() {
		const token = await methods.fetchToken()
	
		const data = { 
			clientId: state.clientId,
			token: token
		}
		fetch(`${swagger_url}/get-driver-coverages.php`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Driver Coverages:', data)
			state.driverCoverages = data;
		})
		.catch((error) => {
			console.error('Error:', error)
		})
	},
	
	async addDriver(driver) {
			const token = await methods.fetchToken()
			driver.token = token
			driver.clientId = state.clientId
			state.addingDriver = true
			fetch(`${swagger_url}/create-driver.php`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', },
				body: JSON.stringify(driver)
			})
			.then((response) =>  response.json())
			.then((data) => {
				if(data.status === 200) {
					console.log('Driver Added: ', data.message)
					methods.closeSidePanel()
					methods.getPendingChanges()
				}
				if(data.status === 400) {
					console.warn('Error: ', data.message)
				}
				state.addingDriver = false
			})
			.catch((error) => { console.error('Error:', error), state.addingDriver = false })
	},

	async removeDriver(id, effectiveDate) {
		const token = await methods.fetchToken()
		const data = { 
			driverId: id,
			effectiveDate: effectiveDate,
			token: token
		}
	
		fetch(`${swagger_url}/remove-driver.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => {
			if(response.status === 200) {
				// console.log('Driver Removed: ', response)
				_.filter(state.drivers, ['id', id])[0].isChangePending = true
				methods.getPendingChanges()
			} else {
				console.warn('Something went wrong', response)
			}
			methods.closeRemoveDriver()
			
		})
		.catch((error) => { 
			console.error('Error Removing Driver:', error)
			methods.closeRemoveDriver() 
		})
	},

	async getPolicies() {
		const token = await methods.fetchToken()

		const data = {
			token: token,
			clientId: state.clientId
		}

		fetch(`${swagger_url}/get-policies.php`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			state.policies = data
		})
		.catch((error) => {
			console.error('Error:', error)
		})
	},

	async getPolicyLines(pid) {
		const token = await methods.fetchToken()

		const data = {
			token: token,
			policyId: pid
		}

		fetch(`${swagger_url}/get-policy-lines.php`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			state.policies = data
		})
		.catch((error) => {
			console.error('Error:', error)
		})
	},

	async getUnits() {
		const token = await methods.fetchToken()

		const data = {
			token: token,
			clientId: state.clientId
		}

		fetch(`${swagger_url}/get-units.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Units:', data)
			data.forEach(unit => { // Clean the data a bit.
				unit.value = unit.value ? unit.value.toString() : null 
			})
			state.units = data
		})
		.catch((error) => {
			console.error('Get Units Error:', error)
		})
	},

	async getUnitCoverages() {
		const token = await methods.fetchToken()
	
		const data = { 
			clientId: state.clientId,
			token: token
		}
		
		fetch(`${swagger_url}/get-unit-coverages.php`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Unit Coverages:', data)
			state.unitCoverages = data;
		})
		.catch((error) => {
			console.error('Unit Coverages Error:', error)
		})
	},

	async updateUnit(unit) {
		const token = await methods.fetchToken()
		console.log(unit)
		const data = {
			unitId: state.updateUnit.id,
			newUnitNumber: unit.unit,
			newUnitValue: unit.value,
			effectiveDate: unit.effectiveDate,
			token: token,
			clientId: state.clientId
		}

		fetch(`${swagger_url}/update-unit.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => {
			console.log(response)
			if(response.status === 200) {
				return response
			} else {
				console.log('Error: ', response.status)
			}
		})
		.then((data) => {
			// console.log('Update Unit:', data)
			methods.closeUpdateUnit()
			methods.getPendingChanges()

		})
		.catch((error) => {
			console.error('Update Unit Error:', error)
		})
	},

	async addUnit(unit) {
		const token = await methods.fetchToken()
		unit.token = token
		unit.clientId = state.clientId
		
		fetch(`${swagger_url}/create-unit.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(unit)
		})
		.then((response) =>  response.json())
		.then((data) => {
			if(data.status === 200) {
				console.log('Unit Added: ', data.message)
				methods.closeSidePanel()
				methods.getPendingChanges()
			}
			if(data.status === 400) {
				console.warn('Error Adding Unit: ', data.message)
			}
		})
		.catch((error) => { console.error('Error:', error) })
	},

	async removeUnit(id, effectiveDate) {
		const token = await methods.fetchToken()
		const currentDate = new Date();
		const data = { 
			unitId: id,
			effectiveDate: effectiveDate,
			token: token
		}
	
		fetch(`${swagger_url}/remove-unit.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => {
			if(response.status === 200) {
				// console.log('Removed Unit: ', response)
				_.filter(state.units, ['id', id])[0].isChangePending = true
				methods.getPendingChanges()
			} else {
				console.warn('Error Removing Unit:', response)
			}
			methods.closeRemoveUnit()
		})
		.catch((error) => { console.error('Error:', error), methods.closeRemoveUnit() })
	},

	async getDocument(fileId, filename) {
		console.log(filename)
		const token = await methods.fetchToken()

		const data = {
			token: token,
			clientId: state.clientId,
			id: fileId
		}

		fetch(`${swagger_url}/get-document.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => response.blob())
		.then((blob) => {
			// console.log('Document:', blob)
			const a = document.createElement('a')
			a.href = window.URL.createObjectURL(blob)
			a.download = filename
			a.click()
		})
		.catch((error) => {
			console.error('Get Document Error:', error)
		})
	},

	async getDocuments() {
		const token = await methods.fetchToken()

		const data = {
			token: token,
			clientId: state.clientId
		}

		fetch(`${swagger_url}/get-documents.php`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify(data)
		})
		.then((response) => response.json())
		.then((data) => {
			// console.log('Documents:', data)
			state.documents = data
		})
		.catch((error) => {
			console.error('Get Documents Error:', error)
		})
	},
	
	async fetchToken() {
		let token = ''
		if (methods.checkCookie('swagger_token')) {
			token = methods.getCookie('swagger_token')
		} else {
			await fetch(`${swagger_url}/get-token.php`)
				.then((response) => {
					return response.json()
				})
				.then((data) => {
					// console.log('Token', data)
					token = data.access_token
					methods.setCookie('swagger_token', data.access_token, 30)
				});
		}
		return token
	},
	
	setCookie(cname, cvalue, extmins) {
		const d = new Date();
		d.setTime(d.getTime() + (extmins * 60000));
		let expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},
	
	getCookie(cname) {
		let name = cname + "=";
		
		let ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},
	
	checkCookie(cname) {
		let ck = methods.getCookie(cname);
		if (ck != "") {
			return true
		} else {
			return false
		}
	},

	formattedDate(val) {
		let d = new Date(val)
		return d.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
	},

	formatCurrency(num) {
		if(num !== null) return `$${num.toLocaleString("en-US")}`
	},

	hasCoverage(unit, coverage) {
		return _.filter(unit, ['code', coverage]).length
	}
}

const activePolicies = computed(() => {
	// Call returns all Policies, even expired. So we need to filter to only valid policies.
	return _.filter(data, policy => {
		const now = Date.now()
		const expires = Date.parse(policy.expirationDate)
		// console.log('Now:', now, ' Expires:', expires, 'Valid: ', now<=expires)
		if(now <= expires) return true
	})
})

export default {
	state,
	methods
}