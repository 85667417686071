<template><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="classList"><title>{{title}}</title><g :fill="color"><path d="M23,2h-4c0,1.105-0.895,2-2,2s-2-0.895-2-2H9c0,1.105-0.895,2-2,2S5,3.105,5,2H1C0.448,2,0,2.448,0,3v18 c0,0.552,0.448,1,1,1h22c0.552,0,1-0.448,1-1V3C24,2.448,23.552,2,23,2z M8,9c1.105,0,2,0.895,2,2c0,1.105-0.895,2-2,2s-2-0.895-2-2 C6,9.895,6.895,9,8,9z M4,17c0-1.657,1.343-3,3-3h2c1.657,0,3,1.343,3,3H4z M20,16h-6v-2h6V16z M20,12h-6v-2h6V12z"></path></g></svg></template>

<script>
export default {
	props: {
        classList: {
            type: String
        },
        color: {
            type: String,
            default: "#1C2427"
        },
		title: {
			type: String,
			default: "archive content"
		}
	}
}
</script>