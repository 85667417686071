<template><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="classList"><title>{{title}}</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" :stroke="color" stroke-miterlimit="10"><line x1="6" y1="12" x2="3" y2="12"></line> <line x1="6" y1="17" x2="5" y2="17"></line> <polyline points="17.829,18 23,18 23,12 19,10 18,6 10,6 10,18 12.171,18 " stroke-linecap="butt"></polyline> <line x1="1" y1="7" x2="6" y2="7"></line> <polyline points="1,2 10,2 10,6 "></polyline> <circle cx="15" cy="19" r="3"></circle> <polyline points="14,9 14,11 16,11 "></polyline></g></svg></template>
<script>
export default {
	props: {
        classList: {
            type: String
        },
        color: {
            type: String,
            default: "#1C2427"
        },
		title: {
			type: String,
			default: "Units"
		}
	}
}
</script>