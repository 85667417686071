<template><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="classList"><title>{{title}}</title><g :fill="color"><path d="M20,1H4A3,3,0,0,0,1,4V20a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V4A3,3,0,0,0,20,1ZM4,3H20a1,1,0,0,1,1,1V15H15v3H9V15H3V4A1,1,0,0,1,4,3Z" :fill="color"></path><rect x="6" y="6" width="12" height="2"></rect><rect x="6" y="10" width="12" height="2"></rect></g></svg></template>

<script>
export default {
	props: {
        classList: {
            type: String
        },
        color: {
            type: String,
            default: "#1C2427"
        },
		title: {
			type: String,
			default: "archive content"
		}
	}
}
</script>