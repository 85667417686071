import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import ClaimsView from '../views/ClaimsView.vue'
import DriversView from '../views/DriversView.vue'
import UnitsView from '../views/UnitsView.vue'
import DocumentsView from '../views/DocumentsView.vue'
import DiscountsView from '../views/DiscountsView.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/claims',
    name: 'claims',
    component: ClaimsView
  },
  {
    path: '/drivers',
    name: 'drivers',
    component: DriversView
  },
  {
    path: '/units',
    name: 'units',
    component: UnitsView
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentsView
  },
  {
    path: '/discounts',
    name: 'discounts',
    component: DiscountsView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
