<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="classList"><title>{{title}}</title><g :fill="color"><rect y="1" width="9" height="2"></rect> <rect x="2" y="5" width="7" height="2"></rect> <rect x="4" y="9" width="5" height="2"></rect> <rect x="6" y="13" width="3" height="2"></rect> <path d="M23.447,11.105l-3.589-1.794L18.97,5.758C18.859,5.312,18.459,5,18,5h-1.625H11c0,0,0,12.451,0,13 s0.051,1,0.051,1c0.252,2.244,2.139,4,4.449,4s4.197-1.756,4.449-4H23c0.552,0,1-0.448,1-1v-6 C24,11.621,23.786,11.275,23.447,11.105z M15.5,21c-1.378,0-2.5-1.121-2.5-2.5s1.122-2.5,2.5-2.5s2.5,1.121,2.5,2.5 S16.878,21,15.5,21z M13,10V7h4.344L18,10H13z"></path></g></svg></template>
<script>
export default {
	props: {
        classList: {
            type: String
        },
        color: {
            type: String,
            default: "#1C2427"
        },
		title: {
			type: String,
			default: "Units"
		}
	}
}
</script>