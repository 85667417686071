<template>
	<div class="relative h-full" @mouseleave="(enabled = false)">
		<div class="select-input cursor-pointer relative flex justify-between items-center w-full h-full rounded-md px-6 pt-5 pb-3 border border-gray-300 shadow-sm focus:border-blue-900 focus:ring-blue-900 sm:text-sm md:text-lg z-0" @click="(enabled = true)">
			<label class="block text-xs text-gray-500 font-display font-bold uppercase absolute left-4 -top-1 px-2 bg-white">{{ label }}</label>
			<div class="text-sm" v-text="value"></div>
			<ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
		</div>
		<div class="options-container bg-white rounded-md shadow-xl h-80 min-h-80 max-h-80 absolute left-0 hidden top-0 w-full z-10 overflow-x-hidden" :class="{'show-select': enabled}">
			<div class="flex transition" :class="{'-translate-x-full': typeOptions.length}">
				<div class="optionset w-full min-w-full px-3 pb-3">
					<label class="block text-sm text-blue-900 font-display font-bold uppercase bg-white pt-3 sticky top-0 z-10"><span class="flex px-2">{{ label }}</span></label>
					<div class="options pt-2">
						<div class="option py-2 px-3 rounded-md flex justify-between cursor-pointer text-sm" 
							:class="{'bg-gray-100' : index % 2 === 0}"
							v-for="(option, index) in options"	
							@click="selectUnitType(option)"
						>
							{{ option.name }} <ChevronDownIcon class="h-5 w-5 text-gray-400 -rotate-90" aria-hidden="true" v-if="option.subtypes.length" />
						</div>
					</div>
				</div>
				<div class="optionset w-full min-w-full px-3 pb-3">
					<label class="block text-sm text-blue-900 font-display font-bold uppercase bg-white pt-3 sticky top-0 z-10"><span class="flex px-2"><ChevronDownIcon class="h-5 w-5 text-gray-400 rotate-90 mr-2 cursor-pointer" aria-hidden="true" @click="unitType = ''" /> {{ unitType }}</span></label>
					<div class="options pt-2">
						<div class="text-sm p-2 rounded-md flex justify-between cursor-pointer" 
							:class="{'bg-gray-100' : index % 2 === 0}"
							@click="selectSubType(type)"
							v-for="(type, index) in typeOptions"
						>
							{{ type }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.show-select {
		display: block;
	}
</style>
<script setup>
import { ref, reactive, computed, watch } from 'vue'
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
	label: {
		type: String,
		default: "",
		required: true
	}
})

const emit = defineEmits(['updateUnitType'])

let enabled = ref(false)
let unitType = ref("")
let specificType = ref("")

const value = computed(() => {
	return specificType.value.length ? specificType.value : unitType.value
})

watch(enabled, async () => {
	document.querySelector('.options-container').scrollTo(0,0)
})

const typeOptions =  computed(() => {
	console.log(unitType.value)
	// return unitType.value
	let subtypes = []
	const option = _.filter(options, option => {
		return option.name === unitType.value
	})

	if(enabled.value) {
		document.querySelector('.options-container').scrollTo(0,0)
	}
	


	return option.length ? option[0].subtypes : []
})

function selectUnitType(option) {
	unitType.value = ""
	specificType.value = ""

	unitType.value = option.name
	if(!option.subtypes.length) {
		enabled.value = false;
	}
	emit('updateUnitType', unitType.value, specificType.value)
}

function selectSubType(type) {
	specificType.value = type
	enabled.value = false
	emit('updateUnitType', unitType.value, specificType.value)
}



const options = [
	{
		name: 'Tractor',
		subtypes: ['Agricultural Truck','Bucket Truck/Cherry Picker','Car Carrier','Catering/Lunch Truck','Cement Mixer','Delivery/Step Van','Dump Truck','Flatbed Truck','Garbage Truck (Front Loader)','Garbage Truck (Roll-on)','Ice Cream Truck','Pump Truck (Cement)','Refrigerated Box/Straight','Spotter','Stake Body Truck','Street Sweeper','Tank Truck','Tow Truck','Truck Tractor','Emergency Vehicle','Military Vehicle']
	},
	{
		name: 'Trailer',
		subtypes: ['Auto Hauler', 'Bottom Dump Trailer', 'Bulk Commodity Trailer', 'Chassis', 'Concession Trailer', 'Conestoga', 'Dry Freight Trailer', 'Dump Body Trailer / Transfer Box', 'Flatbed Trailer', 'Gooseneck Trailer', 'Hopper', 'Horse Trailer', 'Livestock Trailer', 'Logging Trailer', 'Low-Boy Trailer', 'Pole Trailer', 'Refrigerated Dry Freight Trailer', 'Tank Trailer', 'Tilt Trailer', 'Travel Trailer', 'Utility Trailer']
	},
	{
		name: 'Car, Van, or SUV',
		subtypes: ['Car', 'Hearse', 'Limousine', 'Mini Van', 'Passenger Van', 'Sport Utility Vehicle', 'Emergency Vehicles']
	},
	{
		name: 'Cargo Van',
		subtypes: []
	},
	{
		name: 'Box/Straight Truck',
		subtypes: []
	},
	{
		name: 'Pickup-Standard Bed',
		subtypes: ['Pickup','Service Truck']
	},
	{
		name: 'Pickup-Modified Bed',
		subtypes: ['Pickup','Service Truck']
	},
	{
		name: 'Bus',
		subtypes: []
	},
	{
		name: 'Recreational Vehicle',
		subtypes: ['Motorhome', '5th Wheel Camper/Trailer', 'Camper (Popup, Truck Camper, Camper Trailer, etc)']
	},
]

</script>

