<template>
	<div class="bg-white rounded-lg max-w-5xl m-auto relative shadow-2xl">
		<div class="p-4 sm:p-6">
			<div class="sm:flex sm:items-center sm:justify-between">
				<div class="flex pl-4">
					<h1 class="font-display font-bold text-2xl text-blue-900 flex items-center mr-6">
						<DriversIcon color="#969BAD" classList="mr-3"></DriversIcon> <span v-if="filtered_drivers.length" class="pr-1">{{ modelValue.length }}</span> {{ title }}
					</h1>
					<div class="flex items-center">
						<div class="input">
							<input type="text" id="input-search" v-model="search.value" placeholder="Search"
								class="border-collapse border-transparent">
						</div>
					</div>
				</div>
				<div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none actions flex ml-auto">
					<button class="btn ghost mr-6" @click="csvExporter.generateCsv(export_drivers)" :disabled="!modelValue.length">
						<svg xmlns="http://www.w3.org/2000/svg"
							width="20" height="15.613" viewBox="0 0 20 15.613">
							<g transform="translate(1 1)">
								<path d="M461.611,279.889a4.065,4.065,0,0,0-3.7-7.231,6.222,6.222,0,1,0-9.626,6.617"
									transform="translate(-445.664 -267.979)" fill="none" stroke="#231f20"
									stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
								<g transform="translate(6.496 7.035)">
									<line y2="6.171" transform="translate(2.764)" fill="none" stroke="#231f20"
										stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
									<path d="M457.678,279.078l-2.765,2.765-2.753-2.765"
										transform="translate(-452.16 -275.014)" fill="none" stroke="#231f20"
										stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
								</g>
							</g>
						</svg> Export</button>
					<button class="btn" @click="store.methods.openSidePanel()"><svg xmlns="http://www.w3.org/2000/svg"
							width="12" height="12" viewBox="0 0 12 12">
							<path d="M18,11H13V6H11v5H6v2h5v5h2V13h5Z" transform="translate(-6 -6)" fill="#fff" />
						</svg> Add Driver</button>
				</div>
			</div>
			<div class="-mx-4 mt-8 overflow-hidden sm:-mx-6 md:mx-0">
				<table class="min-w-full">
					<thead>
						<tr>
							<th scope="col"
								class="py-2 pl-10 pr-3 text-left text-xs uppercase font-display font-semibold text-blue-700">
								<!-- ID -->
							</th>
							<th scope="col"
								class="py-2 pr-3 text-left text-xs uppercase font-display font-semibold text-blue-700">
								Name</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 lg:table-cell">
								License</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 sm:table-cell">
								State</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 sm:table-cell">
								Hire Date</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 sm:table-cell">
								Year Licensed</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 sm:table-cell">
								Coverages</th>
							<th scope="col"
								class="px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700">
							</th>
						</tr>
					</thead>
					<tbody class="bg-white">
						<tr v-for="(driver, driverIdx) in filtered_drivers" :key="driver.id" v-if="filtered_drivers.length">
							<td class="w-14 font-semibold py-5 text-blue-900 align-top lg:align-middle">
								<div class="status flex items-start justify-center" :class="driverStatus(driver)">
									<!-- {{ driver.id }} -->
									<PendingStatusIcon />
									<ReviewStatusIcon />
								</div>
							</td>
							<td class="w-full max-w-0 py-4 pr-3 font-semibold text-blue-900 sm:w-auto sm:max-w-none">
								<!-- <a href="#" class="font-display">{{ driver.firstName }} {{ driver.lastName }}</a> -->
								<div class="font-display">{{ driver.firstName }} {{ driver.lastName }}</div>
								<dl class="font-normal lg:hidden">
									<!-- <dt class="sr-only">ID</dt>
									<dd class="mt-0.25 truncate text-blue-900 text-sm font-semibold uppercase">
										<span>ID:</span> {{ driver.id }}</dd> -->
									<dt class="sr-only">License</dt>
									<dd class="mt-0.25 truncate text-blue-900 text-sm font-semibold uppercase">
										<span>Lic:</span> {{ driver.licenseNumber }}</dd>
									<dt class="sr-only sm:hidden">State</dt>
									<dd class="mt-0.25 truncate text-gray-500 sm:hidden text-xs font-semibold "><span
											class="uppercase">State:</span> {{ driver.state }}</dd>
									<dt class="sr-only sm:hidden">Hire Date</dt>
									<dd class="mt-0.25 truncate text-gray-500 sm:hidden text-xs font-semibold "><span
											class="uppercase">Hire Date:</span> {{ store.methods.formattedDate(driver.dateOfHire) }}
									</dd>
									<dt class="sr-only sm:hidden">Year Licensed</dt>
									<dd class="mt-0.25 truncate text-gray-500 sm:hidden text-xs font-semibold "><span
											class="uppercase">Year Licensed:</span> {{ driver.yearOfCDL }}</dd>
									<dd class="mt-0.25 truncate text-gray-500 sm:hidden text-xs font-semibold ">
										<span class="uppercase">Coverages:</span> 
										<span class="flex">
											<AutoLiabilityIcon class="mr-2" v-if="store.methods.hasCoverage(driver.coverages, 'TRAL')" /><PhysicalDamageIcon class="mr-2" v-if="store.methods.hasCoverage(driver.coverages, 'TRPH')" /><CargoIcon class="mr-2" v-if="store.methods.hasCoverage(driver.coverages, 'TRCA')" />
										</span>
									</dd>
								</dl>
							</td>
							<td class="hidden px-3 py-4 text-blue-700 sm:table-cell text-sm sm:pl-4">{{ driver.licenseNumber }}</td>
							<td class="hidden px-3 py-4 text-blue-700 sm:table-cell text-sm sm:pl-4">{{ driver.state }}</td>
							<td class="hidden px-3 py-4 text-blue-700 sm:table-cell text-sm sm:pl-4">{{	store.methods.formattedDate(driver.dateOfHire) }}</td>
							<td class="hidden px-3 py-4 text-blue-700 sm:table-cell text-sm sm:pl-4">{{ driver.yearOfCDL }}</td>
							<td class="hidden px-3 py-4 text-blue-700 sm:table-cell text-sm sm:pl-4">
								<div class="flex">
									<AutoLiabilityIcon class="mr-2" v-if="store.methods.hasCoverage(driver.coverages, 'TRAL')" /><PhysicalDamageIcon class="mr-2" v-if="store.methods.hasCoverage(driver.coverages, 'TRPH')" /><CargoIcon class="mr-2" v-if="store.methods.hasCoverage(driver.coverages, 'TRCA')" />
								</div>
							</td>
							<td class="px-6 py-4 text-blue-700 flex md:w-40 ml-auto op">
								<!-- <button class="opacity-25 hover:opacity-100 transition">
									<EditIcon :title="'Edit ' + driver.firstName + ' ' + driver.lastName"></EditIcon>
								</button> -->
								<button class="opacity-25 hover:opacity-100 transition" v-if="!driver.isChangePending" @click="store.methods.loadRemoveDriver(driver)">
									<TrashCanIcon :title="'Delete ' + driver.firstName + ' ' + driver.lastName">
									</TrashCanIcon>
								</button>
							</td>
						</tr>
						<tr class="loading" v-else v-for="rows in store.state.fakeRows">
							<td class="w-14"></td>
							<td class="w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none">
								<div class="ui-box w-32 h-2 lg:h-4 mb-1 lg:mb-0 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
								<dl class="lg:hidden">
									<dt class="sr-only">Year, Make and Model</dt>
									<dd class="mt-0.25">
										<div class="ui-box w-16 h-2 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
									</dd>
									<dt class="sr-only sm:hidden">VIN</dt>
									<dd class="mt-0.25 sm:hidden">
										<div class="ui-box w-10 h-2 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>	
									</dd>
									<dt class="sr-only sm:hidden">Value</dt>
									<dd class="mt-0.25 sm:hidden">
										<div class="ui-box w-4 h-2 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
									</dd>
									<dt class="sr-only sm:hidden">Coverages</dt>
									<dd class="mt-0.25 sm:hidden">
										<div class="ui-box w-8 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
									</dd>
								</dl>
							</td>
							<td class="hidden px-3 py-4 lg:table-cell sm:pl-4">
								<div class="ui-box w-40 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
							</td>
							<td class="hidden px-3 py-4  sm:table-cell sm:pl-4">
								<div class="ui-box w-20 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
							</td>
							<td class="hidden px-3 py-4 sm:table-cell sm:pl-4">
								<div class="ui-box w-26 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
							</td>
							<td class="hidden px-3 py-4 sm:table-cell m:pl-4">
								<div class="flex">
									<div class="ui-box w-10 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded mr-4"></div>
								</div>
							</td>
							<td class="px-6 py-4 text-blue-700 flex md:w-40">
								<div class="ui-box w-16 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded mr-4"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

</template>

<script setup>
import { inject, reactive, computed } from 'vue'
import DriversIcon from '@/icons/DriversIcon.vue'
import TrashCanIcon from '@/icons/TrashCanIcon.vue'
import PendingStatusIcon from '@/icons/PendingStatusIcon.vue'
import ReviewStatusIcon from '@/icons/ReviewStatusIcon.vue'
import { ExportToCsv } from 'export-to-csv';
import AutoLiabilityIcon from '@/icons/AutoLiabilityIcon.vue'
import PhysicalDamageIcon from '@/icons/PhysicalDamageIcon.vue'
import CargoIcon from '@/icons/CargoIcon.vue'

const store = inject('store')

const props = defineProps({
	modelValue: {
		default: []
	},
	title: {
		type: String,
		required: true
	}
})
const csvExporter = new ExportToCsv({
	filename: "Drivers",
	showLabels: true,
	useKeysAsHeaders: true
});


const search = reactive({value: ''})
const remove = reactive({})

const export_drivers = computed(() => {
	let drivers = []
	props.modelValue.forEach(d => {
		const driver = {
			"First Name": d.firstName !== null ? d.firstName : '',
			"Last Name": d.lastName !== null ? d.lastName : '',
			"License Number": d.licenseNumber !== null ? d.licenseNumber : '',
			"Licenced State": d.driverState !== null ? d.driverState : '',
			"Date Of Birth": d.dateOfBirth !== null ? new Date(d.dateOfBirth).toLocaleDateString() : '',
			"Original Year Licensed": d.yearOfCDL !== null ? d.yearOfCDL : '',
			"Hire Date": d.dateOfHire !== null ? new Date(d.dateOfHire).toLocaleDateString() : '',
			"Commments": d.comments !== null ? d.comments : '',
			"Address": d.address !== null ? d.address : '',
			"City": d.city !== null ? d.city : '',
			"State": d.state !== null ? d.state : '',
			"Zip": d.zipCode !== null ? d.zipCode : ''
		}
		drivers.push(driver)
	})
	return drivers
})

const filtered_drivers = computed(() => {
	let filtered_list = props.modelValue
	if (search.value.length) {
		filtered_list = _.filter(filtered_list, (o) => {
			return o.firstName.toLowerCase().includes(search.value.toLowerCase()) || o.lastName.toLowerCase().includes(search.value.toLowerCase())
		})
	}
	return filtered_list
})

function driverStatus(driver) {
	let status = ''
	if (!driver.needsApproval)
		status = 'status-approved'
	if (Date.parse(driver.effectiveDate) > Date.now())
		status = 'status-pending'
	if (driver.isChangePending)
		status = 'status-pending'
	return status
}

function removeDriver(driver) {
	console.log(driver)
	remove.id = driver.id
	store.state.removeDriverOpen = true;
	// store.methods.removeDriver(driver.id)
}

</script>