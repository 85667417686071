<template>
<div class="top-navigation bg-blue-900 pl-10 pb-2 pr-10 sticky top-0 z-10">
    <div class="pt-8 pb-4 max-w-5xl m-auto flex items-center justify-between">
        <div class="logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="178" height="34.053" viewBox="0 0 178 34.053">
                <g transform="translate(41.807 8.409)" data-name="Logo Text">
                    <path d="M360.726,2305.771h8.745l-1.17,3.723H355.539l5.234-16.655h4.017Z" transform="translate(-339.021 -2292.839)" fill="#fff"/>
                    <path d="M306.776,2319.15l-1.3,4.154h-7.72l-1.8,5.731h-4.618l2.239-7.132a3.955,3.955,0,0,1,3.655-2.753Z" transform="translate(-291.333 -2312.381)" fill="#fff"/>
                    <path d="M321.089,2292.985l-.48,1.532a3.958,3.958,0,0,1-3.655,2.753H305.876l1.343-4.285Z" transform="translate(-302.134 -2292.948)" fill="#fff"/>
                    <path d="M421.135,2303.2l-1.978,6.294h-4.042l1.978-6.294-3.848-10.36h4.63l2.392,6.809,5.54-5.717a3.484,3.484,0,0,1,2.452-1.092h3.285Z" transform="translate(-381.88 -2292.839)" fill="#fff"/>
                    <path d="M508.742,2292.839l-11.48,16.655h-4.115l-.093-10.85-7.329,10.85H481.56l-.3-16.655h4.287l.06,10.8,7.19-10.8h4.385l0,10.752,7.249-10.752Z" transform="translate(-432.395 -2292.839)" fill="#fff"/>
                    <path d="M589.99,2309.494h-4.042l2.032-6.466h-7.324l-2.032,6.466h-4.017l5.234-16.655h4.017l-2.032,6.466h7.324l2.032-6.466h4.042Z" transform="translate(-501.728 -2292.839)" fill="#fff"/>
                    <path d="M663.72,2292.839l-1.17,3.723h-8.745l-.9,2.865h7.3l-1.093,3.478h-7.3l-.9,2.865h8.745l-1.17,3.723H645.724l5.234-16.655Z" transform="translate(-554.549 -2292.839)" fill="#fff"/>
                    <path d="M723.778,2292.839l-1.17,3.723h-8.745l-.9,2.865h7.3l-1.093,3.478h-7.3l-.9,2.865h8.745l-1.17,3.723H705.782l5.234-16.655Z" transform="translate(-599.155 -2292.839)" fill="#fff"/>
                    <path d="M771.737,2305.771h8.745l-1.17,3.723H766.55l5.234-16.655H775.8Z" transform="translate(-644.289 -2292.839)" fill="#fff"/>
                </g>						
                <g data-name="Logo Mark">
                    <circle cx="17.026" cy="17.026" r="17.026" fill="#fc001b"/>
                    <g transform="translate(9.229 10.155)">
                        <path d="M184.384,2320.353l-1.034,3.291h-6.115l-1.426,4.539h-3.658l1.773-5.649a3.133,3.133,0,0,1,2.9-2.181Z" transform="translate(-172.151 -2315.02)" fill="#fff"/>
                        <path d="M195.722,2299.626l-.38,1.213a3.135,3.135,0,0,1-2.9,2.181h-8.775l1.064-3.395Z" transform="translate(-180.707 -2299.626)" fill="#fff"/>
                    </g>
                </g>
            </svg>

        </div>
        <div class="user-menu h-6 w-6 lg:hidden cursor-pointer" @click="toggleNav()"><MobileNav /></div>
    </div>
    <nav class="py-6 lg:py-3  lg:max-w-5xl lg:m-auto px-6 lg:px-0 w-80 lg:w-auto bg-gray-100 lg:bg-transparent right-0 top-0 bottom-0 fixed lg:shadow-none lg:relative flex flex-col lg:flex-row translate-x-full lg:translate-x-0 transition duration-500" :class="{'translate-x-0 shadow-2xl': navOpen}" >
        <button class="lg:hidden h-14 w-14 ml-auto" @click="toggleNav()"><XMarkIcon /></button>
        <router-link class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/" @click="toggleNav()" active-class="lg:text-white">Home</router-link>
        <router-link class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/claims" @click="toggleNav()" active-class="lg:text-white">Claims</router-link>
        <router-link class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/drivers" @click="toggleNav()" active-class="lg:text-white">Drivers</router-link>
        <router-link class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/units" @click="toggleNav()" active-class="lg:text-white">Units</router-link>
        <router-link class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/documents" @click="toggleNav()" active-class="lg:text-white">Documents</router-link>
        <a href="https://portal.csr24.com/mvc/5875217" target="_blank" class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/certificates" active-class="lg:text-white">Certificates</a>
        <router-link class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/discounts" @click="toggleNav()" active-class="lg:text-white">Discounts</router-link>
        <router-link class="p-4 font-display font-medium text-blue-900 lg:text-white" to="/users" @click="toggleNav()" active-class="lg:text-white">Users</router-link>
        <a href="https://goflywheel.com/" class="p-4 font-display font-medium text-blue-900 lg:text-white" target="_blank">Support</a>
    </nav>
</div>
</template>
<script setup>
import MobileNav from '@/icons/MobileNavIcon.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

import { ref } from 'vue'

const navOpen = ref(false)

function toggleNav(){
    navOpen.value = !navOpen.value
}
</script>