<template>
	<div class="bg-white rounded-lg max-w-5xl m-auto relative shadow-2xl">
		<div class="p-4 sm:p-6">
			<div class="sm:flex sm:items-center sm:justify-between">
				<div class="flex pl-4">
					<h1 class="font-display font-bold text-2xl text-blue-900 flex items-center mr-6">
						<DriversIcon color="#969BAD" classList="mr-3"></DriversIcon> <span v-if="modelValue.length" class="pr-1">{{ modelValue.length }}</span> {{ title }}
					</h1>
				</div>
			</div>
			<div class="-mx-4 mt-8 overflow-hidden sm:-mx-6 md:mx-0">
				<table class="min-w-full">
					<thead>
						<tr>
							<th scope="col"
								class="py-2 pl-6 pr-3 text-left text-xs uppercase font-display font-semibold text-blue-700">
								Filename</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 sm:table-cell">
								Created</th>
							<th scope="col"
								class="px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700">
							</th>
						</tr>
					</thead>
					<tbody class="bg-white">
						<tr v-for="(item, idx) in modelValue" :key="item.id" v-if="modelValue.length">
							<td class="w-full max-w-0 py-4 pl-6 pr-3 font-semibold text-blue-900 sm:w-auto sm:max-w-none">
								<div class="font-display">{{ item.description }}</div>
								<dl class="font-normal lg:hidden">
									<dt class="sr-only">License</dt>
									<dd class="mt-0.25 truncate text-blue-900 text-sm font-semibold uppercase">
										<span>Created:</span> {{ new Date(item.attachedDate).toLocaleDateString() }}</dd>
								</dl>
							</td>
							<td class="hidden px-3 py-4 text-blue-700 sm:table-cell text-sm sm:pl-4">{{ new Date(item.attachedDate).toLocaleDateString() }}</td>
							<td class="px-6 py-4 text-blue-700 flex md:w-40 ml-auto">
								<button class="opacity-25 hover:opacity-100 transition" @click="store.methods.getDocument(item.id, item.description.replaceAll(' ', '-').toLowerCase())">
									Download
								</button>
							</td>
						</tr>
						<tr class="loading" v-else v-for="rows in store.state.fakeRows">
							<td class="w-14"></td>
							<td class="w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none">
								<div class="ui-box w-32 h-2 lg:h-4 mb-1 lg:mb-0 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
								<dl class="lg:hidden">
									<dt class="sr-only"></dt>
									<dd class="mt-0.25">
										<div class="ui-box w-16 h-2 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
									</dd>
									<dt class="sr-only sm:hidden"></dt>
									<dd class="mt-0.25 sm:hidden">
										<div class="ui-box w-10 h-2 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>	
									</dd>
									<dt class="sr-only sm:hidden"></dt>
									<dd class="mt-0.25 sm:hidden">
										<div class="ui-box w-4 h-2 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
									</dd>
									<dt class="sr-only sm:hidden"></dt>
									<dd class="mt-0.25 sm:hidden">
										<div class="ui-box w-8 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
									</dd>
								</dl>
							</td>
							<td class="hidden px-3 py-4 lg:table-cell sm:pl-4">
								<div class="ui-box w-40 h-4 flex items-start justify-center bg-blue-700 opacity-20 rounded"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

</template>

<script setup>
import { inject } from 'vue'
import DriversIcon from '@/icons/DriversIcon.vue'

const store = inject('store')

const props = defineProps({
	modelValue: {
		type: Array,
		default: []
	},
	title: {
		type: String,
		required: true
	}
})

</script>