<template>
	<div class="relative">
		<label :for="name" class="block text-xs text-gray-500 font-display font-bold uppercase absolute left-4 -top-1 px-2 bg-white" v-text="label"></label>
		<!-- <input @input="handleUpdate" placeholder=" " type="date" :name="name" :min="min.toISOString().split('T')[0]" :value="value.toISOString().split('T')[0]" :id="name" :maxlength="maxlength" class="block w-full rounded-md px-6 pt-5 pb-3 border-gray-300 shadow-sm focus:border-blue-900 focus:ring-blue-900 text-sm" /> -->
		<input @input="handleUpdate" placeholder=" " type="date" :id="name" :maxlength="maxlength" class="block w-full rounded-md px-6 pt-5 pb-3 border-gray-300 shadow-sm focus:border-blue-900 focus:ring-blue-900 text-sm" />
		<span class="input-optional font-display font-extrabold text-xs uppercase text-gray-300 absolute right-6 top-1/2 -translate-y-1/2 pointer-events-none" v-if="!props.required">Optional </span>
	</div>
</template>

<style>
input:focus ~ span, 
input:not(:placeholder-shown) ~ span {
	display: none;
}

</style>
  
<script setup>
	import { inject, onMounted } from 'vue'
	
	const store = inject('store')
	
	const props = defineProps({
		value: {
			default: ''
		},
		label: {
			type: String,
			required: false,
			default: undefined
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		name: {
			type: String,
			required: true,
			default: ''
		},
		maxlength: {
			type: String,
			required: false,
			default: '256'
		}
	})
	const emit = defineEmits({
		unvalidatedEvent: null,
		updateValue: (s) => {
			if (s && typeof s === 'object') {
				return true
			} else {
				console.warn(`Invalid submit event payload.`)
				return false
			}
		}
	})

	const handleUpdate = (event) => {
		let value = event.target.value
		if(event.target.type === 'date') {
			value = new Date(value)
		}
		emit('updateValue', value, props.name)
	}

	onMounted(() => {
		if(props.value.toString().length) {
			emit('updateValue', props.value.toJSON(), props.name)
		}
	})


</script>