<template>
	<TransitionRoot as="template" :show="store.state.sidePanelOpen">
		<Dialog as="div" class="relative z-10" @close="store.methods.closeSidePanel()">
			<TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
				enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>
			<div class="fixed inset-0" />
			<div class="fixed inset-3 overflow-hidden">
				<div class="absolute inset-0 overflow-hidden">
					<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
						<TransitionChild as="template"
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enter-from="translate-x-full" enter-to="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leave-from="translate-x-0" leave-to="translate-x-full">
							<DialogPanel class="pointer-events-auto w-screen max-w-xl p-6">
								<form class="flex h-full flex-col divide-y divide-gray-500 bg-white shadow-2xl rounded-xl" :class="{submitting: store.state.addingDriver}">
									<div class="h-0 flex-1 overflow-y-scroll">
										<div class="bg-blue-900 py-6 px-4 sm:px-6 rounded-t-xl">
											<div class="flex items-center justify-between">
												<DialogTitle
													class="text-2xl font-display uppercase font-extrabold text-white">
													Add Driver</DialogTitle>
												<div class="ml-3 flex h-7 items-center">
													<button type="button"
														class="rounded-md text-center bg-blue-900 hover:bg-blue-700 text-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-white"
														@click="store.methods.closeSidePanel()">
														<span class="sr-only">Close panel</span>
														<XMarkIcon class="h-6 w-6" aria-hidden="true" />
													</button>
												</div>
											</div>
										</div>
										<div class="flex flex-1 flex-col justify-between">
											<div class="divide-y divide-gray-400 px-4 sm:px-6">
												<div class="space-y-6 py-8">
													<fieldset>
														<legend class="text-sm font-extrabold text-blue-900 mb-3 font-display uppercase">Choose Your Coverage</legend>
														<div class="policy mb-3" v-for="(line, lineIdx) in store.state.driverCoverages" :key="line.uniqLine">
															<!-- Coverage Loop -->
															<div class="policy flex rounded-lg border-gray-500 border bg-white px-5 py-4" :class="{'border-blue-900': policyLineIsSelected(line.uniqLine)}">
																<Toggle :locked="(line.code === 'TRAL') ? true : false" @update-value="updatePolicyLines(line.uniqLine)"  :uniqLine="line.uniqLine" />
																
																<div class="flex justify-between items-center w-full">
																	<div>
																		<div><strong class="font-display text-lg text-blue-700">{{ line.displayName }}</strong></div>
																		<div class="flex text-xs font-semibold mt-1">
																			<div><strong class="uppercase text-gray-500 font-display font-extrabold pr-1">Expires</strong> {{ store.methods.formattedDate(line.expirationDate) }}</div>
																		</div>
																	</div>
																	<div class="flex items-center" v-if="(line.code === 'TRAL')">
																		<LockedIcon /> <span class="font-display uppercase text-blue-900 font-extrabold text-xs pl-2">Required</span>
																	</div>
																</div>
															</div>
														</div>
													</fieldset>
												</div>
												<div class="space-y-6 py-8">
													<div class="flex">
														<div class="w-1/2 pr-2" :class="{errors: v$.firstName.$error}">
															<Input name="firstName" label="First Name" @update-value="updateValue" required />
															<div v-for="error in v$.firstName.$errors" class="error">{{ error.$message }}</div>
														</div>
														<div class="w-1/2 pl-2" :class="{errors: v$.lastName.$error}">
															<Input name="lastName" label="Last Name" @update-value="updateValue" required />
															<div v-for="error in v$.lastName.$errors" class="error">{{ error.$message }}</div>
														</div>
													</div>
													<div class="w-full" :class="{errors: v$.dateOfBirth.$error}">
														<Date name="dateOfBirth" label="Birthday" @update-value="updateValue" required />
														<div v-for="error in v$.dateOfBirth.$errors" class="error">{{ error.$message }}</div>
													</div>
													<div class="w-full" :class="{errors: v$.address.$error}">
														<Input name="address" label="Address" @update-value="updateValue" required />
														<div v-for="error in v$.address.$errors" class="error">{{ error.$message }}</div>
													</div>
													<div class="w-full" :class="{errors: v$.city.$error}">
														<Input name="city" label="City" @update-value="updateValue" required />
														<div v-for="error in v$.city.$errors" class="error">{{ error.$message }}</div>
													</div>
													<div class="flex">
														<div class="w-1/2 pr-2" :class="{errors: v$.state.$error}">
															<Listbox label="State" name="state" @update-value="updateValue" />
															<div v-for="error in v$.state.$errors" class="error">{{ error.$message }}</div>
														</div>
														<div class="w-1/2 pl-2" :class="{errors: v$.zipCode.$error}">
															<Input name="zipCode" label="Zip Code" @update-value="updateValue" required />
															<div v-for="error in v$.zipCode.$errors" class="error">{{ error.$message }}</div>
														</div>
													</div>
												</div>

												<div class="space-y-6 py-8">
													<fieldset>
														<legend class="text-sm font-extrabold text-blue-900 mb-3 font-display uppercase">Driver License Details</legend>
														<div class="mt-2 space-y-5">
															<div class="flex">
																<div class="w-1/2 pr-2" :class="{errors: v$.licenseNumber.$error}">
																	<Input name="licenseNumber"  label="Driver's License" @update-value="updateValue" required />
																	<div v-for="error in v$.licenseNumber.$errors" class="error">{{ error.$message }}</div>
																</div>
																<div class="w-1/2 pl-2" :class="{errors: v$.driverState.$error}">
																	<Listbox name="driverState" label="Licensed State" @update-value="updateValue" required />
																	<div v-for="error in v$.driverState.$errors" class="error">{{ error.$message }}</div>
																</div>
															</div>
															<div class="flex">
																<div class="w-1/2 pr-2" :class="{errors: v$.dateOfHire.$error}">
																	<Date name="dateOfHire" label="Hire Date" @update-value="updateValue" required />
																	<div v-for="error in v$.dateOfHire.$errors" class="error">{{ error.$message }}</div>
																</div>
																<div class="w-1/2 pl-2" :class="{errors: v$.yearOfCDL.$error}">
																	<Input type="text" name="yearOfCDL" label="Year CDL was issued" maxlength="4" @update-value="updateValue" required />
																	<div v-for="error in v$.yearOfCDL.$errors" class="error">{{ error.$message }}</div>
																</div>
															</div>
														</div>
													</fieldset>
												</div>

												<div class="space-y-6 py-8">
													<TextareaInput name="comments" label="Comments" rows="6" @update-value="updateValue"  />
												</div>
											</div>
										</div>
									</div>
									<div class="flex flex-shrink-0 justify-end items-end px-4 py-4">
										<div class="mr-auto w-full pr-4" :class="{errors: v$.effectiveDate.$error}">
											<Date name="effectiveDate" label="Change Effective Date" @update-value="updateValue" />
											<div v-for="error in v$.effectiveDate.$errors" class="error">{{ error.$message }}</div>
										</div>
										<div class="flex">
											<button type="submit" class="btn mx-1" @click.prevent="validateDriver()">Request</button>
										</div>
									</div>
								</form>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
  
<script setup>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { inject, reactive, computed } from 'vue'
	import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
	import { XMarkIcon } from '@heroicons/vue/24/outline'
	import Input from '@/components/inputs/Input.vue'
	import Date from '@/components/inputs/Date.vue'
	import Toggle from '@/components/inputs/Toggle.vue'
	import Listbox from '@/components/inputs/Select.vue'
	import TextareaInput from '@/components/inputs/TextareaInput.vue'
	import LockedIcon from '@/icons/LockedIcon.vue'

	// Pull in the global store.
	const store = inject('store')
	// Locally hold the new driver data.
	const driver = reactive({
		firstName: '',
		lastName: '',
		licenseNumber: '',
		state: '',
		address: '',
		city: '',
		driverState: '',
		zipCode: '',
		dateOfBirth: '',
		yearOfCDL: '',
		dateOfHire: '',
		comments: '',
		coverages: [],
		effectiveDate: ''
	})	

	// Validation Rules
	const futureDate = (value) => {
		return value > store.state.currentDate
	}
	const pastDate = (value) => {
		return value < store.state.currentDate
	}
	const mustBe18 = (value) => {
		const age = store.state.currentDate.getFullYear() - value.getFullYear()
		const month = store.state.currentDate.getDate() - value.getDate()
		if(month < 0 || (month == 0 && store.state.currentDate.getDate() < value.getDate())) age--
		console.log(age)
		return age > 18
	}
	const rules = computed(() => {
		return {
			firstName: { required },
			lastName: { required },
			licenseNumber: { required },
			state: { required },
			address: { required	},
			city: { required },
			driverState: { required },
			zipCode: { required },
			dateOfBirth: { 
				required,
				mustBe18: helpers.withMessage('Driver must be at least 18.', mustBe18)
			 },
			yearOfCDL: { required },
			dateOfHire: { 
				required,
				pastDate: helpers.withMessage('The date must be in the past.', pastDate)
			},
			effectiveDate: { 
				required,
				futureDate: helpers.withMessage('The date must be in the future.', futureDate)
			}
		}	
    })
	const v$ = useVuelidate(rules, driver)

	// Validate the driver information and then
	// store it and send it to Swagger API
	async function validateDriver(){
		const valid = await v$.value.$validate()
		if(valid) store.methods.addDriver(driver)
	}

	// Update the value in the local driver variable.
	function updateValue(value, property) {
		driver[property] = value
	}

	function policyLineIsSelected(uniqLine) {
		return driver.coverages.includes(uniqLine)
	}

	// Update the policies added in the local driver variable.
	function updatePolicyLines(uniqLine) {
		// Check to see if the coverage exists and add or remove it accordingly.
		if(driver.coverages.includes(uniqLine)) {
			_.remove(driver.coverages, line => {
				return line === uniqLine
			})
		} else {
			driver.coverages.push(_.filter(store.state.driverCoverages, ['uniqLine', uniqLine])[0].uniqLine)
		}
	}
</script>