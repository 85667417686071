<template>
	<div class="relative pt-8">
		<div class="absolute top-0 left-0 w-full h-28 bg-blue-900"></div>
		<TableUnits title="Units" v-model="store.state.units"></TableUnits>
	</div>	
	<CreateUnit/>
	<UpdateUnit/>
	<RemoveUnit/>
</template>

<script setup>
import TableUnits from '@/components/TableUnits.vue'
import CreateUnit from '@/components/CreateUnit.vue'
import UpdateUnit from '@/components/UpdateUnit.vue'
import RemoveUnit from '@/components/RemoveUnit.vue'
import { inject, onMounted } from 'vue'

const store = inject('store')
store.state.units = store.methods.getUnits(store.state.clientId)

onMounted(() => {
	store.methods.getUnitCoverages()
})
</script>
