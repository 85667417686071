<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.001" viewBox="0 0 22 22.001" :class="class">
        <title>{{ title }}</title>
        <path
            d="M22.287,4.042l-10-3a.985.985,0,0,0-.574,0l-10,3A1,1,0,0,0,1,5C1,18.521,11.522,22.886,11.628,22.929a1.01,1.01,0,0,0,.744,0C12.478,22.886,23,18.521,23,5a1,1,0,0,0-.713-.958Z"
            transform="translate(-1 -0.999)" fill="#969bad" />
        <path
            d="M.24-8.4H-3.4V0h1.944V-2.316H.24c2.244,0,3.648-1.164,3.648-3.036S2.484-8.4.24-8.4ZM.132-3.9H-1.452V-6.816H.132c1.188,0,1.788.54,1.788,1.464S1.32-3.9.132-3.9Z"
            transform="translate(11 14)" fill="#f6f8fa" />
    </svg>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Physical Damage"
        },
        class: {
            type: String,
            default: undefined
        }
    }
}
</script>
  