<template>
	<div class="bg-white rounded-lg max-w-5xl m-auto relative shadow-2xl">
		<div class="p-4 sm:p-6">
			<div class="sm:flex sm:items-center sm:justify-between">
				<div class="flex pl-4">
					<h1 class="font-display font-bold text-2xl text-blue-900 flex items-center mr-6"><ClaimsIcon color="#969BAD" classList="mr-3"></ClaimsIcon>	{{ modelValue.length +' '+title }}</h1>
					<div class="flex items-center">
						<div class="input">
							<input type="text" id="input-search" v-model="search.value" placeholder="Search"
								class="border-collapse border-transparent">
						</div>
					</div>
				</div>
				<div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none actions flex ml-auto">
					<button class="btn ghost mr-6" @click="csvExporter.generateCsv(modelValue)" :disabled="!modelValue.length"><svg xmlns="http://www.w3.org/2000/svg"
							width="20" height="15.613" viewBox="0 0 20 15.613">
							<g transform="translate(1 1)">
								<path d="M461.611,279.889a4.065,4.065,0,0,0-3.7-7.231,6.222,6.222,0,1,0-9.626,6.617"
									transform="translate(-445.664 -267.979)" fill="none" stroke="#231f20"
									stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
								<g transform="translate(6.496 7.035)">
									<line y2="6.171" transform="translate(2.764)" fill="none" stroke="#231f20"
										stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
									<path d="M457.678,279.078l-2.765,2.765-2.753-2.765"
										transform="translate(-452.16 -275.014)" fill="none" stroke="#231f20"
										stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
								</g>
							</g>
						</svg> Export</button>
					<button class="btn" @click="CreateDriverPanel.set(true)"><svg xmlns="http://www.w3.org/2000/svg"
							width="12" height="12" viewBox="0 0 12 12">
							<path d="M18,11H13V6H11v5H6v2h5v5h2V13h5Z" transform="translate(-6 -6)" fill="#fff" />
						</svg> Create Claim</button>
				</div>
			</div>
			<div class="-mx-4 mt-8 overflow-hidden sm:-mx-6 md:mx-0">
				<table class="min-w-full">
					<thead>
						<tr>
							<th scope="col" class="py-2 pl-4 pr-3 text-left text-xs uppercase font-display font-semibold text-blue-700 sm:pl-6">
								Claim ID</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 lg:table-cell">
								Driver</th>
							<th scope="col"
								class="hidden px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700 sm:table-cell">Claim
								Amount</th>
							<th scope="col" class="px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700">Status</th>
							<th scope="col" class="px-3 py-2 text-left text-xs font-display font-semibold uppercase text-blue-700">Date Submitted
							</th>
						</tr>
					</thead>
					<tbody class="bg-white">
						<tr v-for="(claim, claimIdx) in filtered_claims" :key="claim.id">
							<td	class="w-full max-w-0 py-4 pl-4 pr-3 font-semibold text-blue-900 sm:w-auto sm:max-w-none sm:pl-6">
								<a href="#">{{ claim.id }}</a>
								<dl class="font-normal lg:hidden">
									<dt class="sr-only">Driver</dt>
									<dd class="mt-1 truncate text-blue-900">{{ claim.driver }}</dd>
									<dt class="sr-only sm:hidden">Claim Amount</dt>
									<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ claim.amount }}</dd>
								</dl>
							</td>
							<td class="hidden px-3 py-4 text-blue-700 lg:table-cell">{{ claim.driver }}</td>
							<td class="hidden px-3 py-4 text-blue-700 sm:table-cell">{{ claim.amount }}</td>
							<td class="px-3 py-4 text-blue-700">{{ claim.status }}</td>
							<td class="px-3 py-4 text-blue-700">{{ claim.date }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script setup>
import ClaimsIcon from '@/icons/ClaimsIcon.vue'
import { inject, reactive, computed } from 'vue';
const store = inject('store')
const props = defineProps({
	modelValue: {
		type: Array,
		default: []
	},
	title: {
		type: String,
		required: true
	}
})

const search = reactive({value: ''})

const filtered_claims = computed(() => {
	let filtered_list = props.modelValue
	if(search.value.length) {
		
		filtered_list = _.filter(filtered_list, (o) => {
			let content = ''
			content += o.driver !== null ? o.driver : ''
			content += o.id !== null ? o.id : ''
			content += o.status !== null ? o.status : ''
			content = content.toLocaleLowerCase()
			return content.includes(search.value.toLowerCase())
		})
	}
	return filtered_list
})
</script>