<template><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="classList"><title>{{title}}</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" :stroke="color" stroke-miterlimit="10"><polyline points="12,20 1,20 1,1 19,1 19,8 "></polyline> <polyline points="22,14.625 22,22 19,21 16,22 16,14.625 " stroke-linecap="butt"></polyline> <circle cx="19" cy="12" r="4"></circle> <line x1="5" y1="6" x2="14" y2="6"></line> <line x1="5" y1="10" x2="11" y2="10"></line> <line x1="5" y1="14" x2="11" y2="14"></line></g></svg></template>
<script>
export default {
	props: {
        classList: {
            type: String
        },
        color: {
            type: String,
            default: "#1C2427"
        },
		title: {
			type: String,
			default: "Certificates"
		}
	}
}
</script>
