<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.001" viewBox="0 0 22 22.001" :class="class">
		<title>{{ title }}</title>
		<path
			d="M22.287,4.042l-10-3a.985.985,0,0,0-.574,0l-10,3A1,1,0,0,0,1,5C1,18.521,11.522,22.886,11.628,22.929a1.01,1.01,0,0,0,.744,0C12.478,22.886,23,18.521,23,5a1,1,0,0,0-.713-.958Z"
			transform="translate(-1 -0.999)" fill="#969bad" />
		<path
			d="M.618.144a4.316,4.316,0,0,0,3.468-1.5L2.838-2.508a2.677,2.677,0,0,1-2.112,1A2.577,2.577,0,0,1-1.962-4.2,2.577,2.577,0,0,1,.726-6.888,2.7,2.7,0,0,1,2.838-5.9L4.086-7.056A4.337,4.337,0,0,0,.63-8.544,4.316,4.316,0,0,0-3.93-4.2,4.313,4.313,0,0,0,.618.144Z"
			transform="translate(11 14)" fill="#f6f8fa" />
	</svg>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "Cargo"
		},
        class: {
            type: String,
            default: undefined
        }
	}
}
</script>
  