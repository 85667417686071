<template>
	<div class="flex items-center justify-start">
		<Switch
			v-model="enabled"
			:class="(enabled ? 'bg-blue-700 enabled' : 'bg-gray-300'), (locked ? 'cursor-not-allowed' : 'cursor-pointer')"
			class="switch-input relative inline-flex h-6 w-11 items-center rounded-full bg-gray transition"
			:disabled="locked"
		>	
			<span class="sr-only">{{ label }}</span>
			<div 
			:class="enabled ? 'translate-x-3' : '-translate-x-2/4'"
			class="h-4 w-4 transform rounded-full bg-white transition inline-flex justify-center items-center">
				<div v-if="locked">
					<LockedIcon />
				</div>
				<div v-else>
				<!-- <div> -->
					<svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="7.605" viewBox="0 0 10.141 7.605" v-if="enabled">
						<path d="M8.7,1.248,3.38,6.565,1.442,4.628A.845.845,0,1,0,.248,5.823L2.783,8.358a.845.845,0,0,0,1.195,0L9.893,2.442A.845.845,0,1,0,8.7,1.248Z" transform="translate(0 -1)" fill="#212121"/>
					</svg>
				</div>
			</div>
		</Switch>
		<label  class="pl-4 font-display uppercase text-xs font-extrabold text-gray-500 cursor-pointer" :class="{'text-blue-900': enabled}" @click="(enabled = !enabled)">{{ label }}</label>
	</div>
</template>

<script setup>
	import { ref, watch } from 'vue'
	import { Switch } from '@headlessui/vue'
	import LockedIcon from '@/icons/LockedIcon.vue'

	const props = defineProps(
		{
			modelValue: Boolean,
			// uniqLine: String,
			propname: String,
			label: {
				type: String,
				required: false,
				default: ""
			},
			locked: Boolean
		}
	)

	const emit = defineEmits(['updateValue'])

	const enabled = props.locked ? ref(true) : ref(false)
	if(props.locked) {
		emit('updateValue', true, props.propname)
	}

	watch(enabled, async (newItem, oldItem) => {
		emit('updateValue', enabled, props.propname)
	})
	

	
</script>