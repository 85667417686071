<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.001" viewBox="0 0 22 22.001" :class="class">
        <title>{{ title }}</title>
        <path
            d="M22.287,4.042l-10-3a.985.985,0,0,0-.574,0l-10,3A1,1,0,0,0,1,5C1,18.521,11.522,22.886,11.628,22.929a1.01,1.01,0,0,0,.744,0C12.478,22.886,23,18.521,23,5a1,1,0,0,0-.713-.958Z"
            transform="translate(-1 -0.999)" fill="#969bad" />
        <path d="M2.676,0h2.04L.96-8.4H-.96L-4.7,0h1.992l.744-1.8h3.9Zm-4.02-3.276L-.012-6.492,1.32-3.276Z"
            transform="translate(11 14)" fill="#f6f8fa" />
    </svg>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Auto Liability"
        },
        class: {
            type: String,
            default: undefined
        }
    }
}
</script>
  
  