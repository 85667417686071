<template><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="status-pending"><title>{{title}}</title><g fill="#f4ab06"><path fill="#f4ab06" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M12,9H7V4h2v3h3V9z"></path></g></svg></template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "Pending"
		}
	}
}
</script>