<template>
	<TransitionRoot as="template" :show="store.state.sidePanelOpen">
		<Dialog as="div" class="relative z-10" @close="store.methods.closeSidePanel()">
			<TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
				enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>
			<div class="fixed inset-0" />
			<div class="fixed inset-3 overflow-hidden">
				<div class="absolute inset-0 overflow-hidden">
					<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
						<TransitionChild as="template"
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enter-from="translate-x-full" enter-to="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leave-from="translate-x-0" leave-to="translate-x-full">
							<DialogPanel class="pointer-events-auto w-screen max-w-xl p-6">
								<form class="flex h-full flex-col divide-y divide-gray-500 bg-white shadow-2xl rounded-xl">
									<div class="h-0 flex-1 overflow-y-auto">
										<div class="bg-blue-900 py-6 px-4 sm:px-6 rounded-t-xl">
											<div class="flex items-center justify-between">
												<DialogTitle
													class="text-2xl font-display uppercase font-extrabold text-white">
													Add Unit</DialogTitle>
												<div class="ml-3 flex h-7 items-center">
													<button type="button"
														class="rounded-md text-center bg-blue-900 hover:bg-blue-700 text-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-white"
														@click="store.methods.closeSidePanel()">
														<span class="sr-only">Close panel</span>
														<XMarkIcon class="h-6 w-6" aria-hidden="true" />
													</button>
												</div>
											</div>
										</div>
										<div class="flex flex-1 flex-col justify-between">
											<fieldset class="divide-y divide-gray-400 px-4 sm:px-6">
												<div class="space-y-6 py-8">
													<fieldset>
														<legend class="text-sm font-extrabold text-blue-900 mb-3 font-display uppercase">Choose Your Coverage</legend>
														<div class="policy mb-3" v-for="(line, lineIdx) in store.state.unitCoverages" :key="line.uniqLine">

															<!-- Coverage Loop -->
															<div class="policy flex rounded-lg border-gray-500 border bg-white px-5 py-4" :class="{'border-blue-900': policyLineIsSelected(line.uniqLine)}">
																<Toggle :locked="(line.code === 'TRAL') ? true : false" @update-value="updatePolicyLines(line.uniqLine)"  :uniqLine="line.uniqLine" />
																
																<div class="flex justify-between items-center w-full">
																	<div>
																		<div><strong class="font-display text-lg text-blue-700">{{ line.displayName }}</strong></div>
																		<div class="flex text-xs font-semibold mt-1">
																			<div><strong class="uppercase text-gray-500 font-display font-extrabold pr-1">Expires</strong> {{ store.methods.formattedDate(line.expirationDate) }}</div>
																		</div>
																	</div>
																	<div class="flex items-center" v-if="(line.code === 'TRAL')">
																		<LockedIcon /> <span class="font-display uppercase text-blue-900 font-extrabold text-xs pl-2">Required</span>
																	</div>
																</div>
															</div>

														</div>
													</fieldset>
												</div>
												<div class="py-8">
													<legend class="text-sm font-extrabold text-blue-900 mb-3 font-display uppercase">Vehicle Information</legend>
													<div class="w-full mb-4" :class="{errors: v$.vin.$error}">
														<InputVIN name="vin" label="VIN" required @update-value="updateValue" @update-vin="VINAutofill"  />
														<div v-for="error in v$.vin.$errors" class="error">{{ error.$message.replace('Value', 'VIN') }}</div>
													</div>
													<div class="flex mb-4">
														<div class="w-1/2 pr-2" :class="{errors: v$.year.$error}">
															<Input name="year" label="Year" :value="unit.year" required @update-value="updateValue" />
															<div v-for="error in v$.year.$errors" class="error">{{ error.$message }}</div>
														</div>
														<div class="w-1/2 pl-2" :class="{errors: v$.make.$error}">
															<Input name="make" label="Make" :value="unit.make" required @update-value="updateValue" />
															<div v-for="error in v$.make.$errors" class="error">{{ error.$message.replace('Value', 'Make') }}</div>
														</div>
													</div>
													<div class="flex mb-4">
														<div class="w-1/2 pr-2">
															<Input name="model" label="Model" :value="unit.model" @update-value="updateValue" />
															<!-- <div v-for="error in v$.model.$errors" class="error">{{ error.$message }}</div> -->
														</div>
														<div class="w-1/2 pl-2" :class="{errors: v$.vehicleType.$error}">
															<CascadingSelect class="max-h-[54px]" name="unitType" label="Unit Type" @update-unit-type="updateUnitType" />
															<div v-for="error in v$.vehicleType.$errors" class="error">{{ error.$message.replace('Value', 'Unit Type') }}</div>
														</div>
													</div>
													<div class="flex mb-4">
														<div class="w-1/2 pr-2" :class="{errors: v$.value.$error}">
															<Input name="value" label="Value" required @update-value="updateValue" />
															<div v-for="error in v$.value.$errors" class="error">{{ error.$message }}</div>
														</div>
														<div class="w-1/2 pl-2">
															<Input name="unit" label="Internal Unit ID" @update-value="updateValue" />
														</div>
													</div>
													<div class="flex">
														<div class="w-1/2 pr-2" :class="{errors: v$.stateOfVehicleRegistration.$error}">
															<Listbox name="stateOfVehicleRegistration" label="Licensed State" @update-value="updateValue" />
															<div v-for="error in v$.stateOfVehicleRegistration.$errors" class="error">{{ error.$message.replace('Value', 'Licensed State') }}</div>
														</div>
														<div class="w-1/2 pl-2" :class="{errors: v$.zip.$error}">
															<Input name="zip" label="Licensed Zip" required @update-value="updateValue" />
															<div v-for="error in v$.zip.$errors" class="error">{{ error.$message.replace('Value', 'Licensed Zip') }}</div>
														</div>
													</div>
												</div>
											</fieldset>
											<fieldset class="px-4 sm:px-6">
												<div class="pt-8 pb-6 border-t border-gray-400" :class="{'border-b mb-8 pb-8': unit.hasAdditionalInsured }">
													<div class="w-full">
														<Toggle @update-value="updateValue" propname="hasAdditionalInsured" label="Has an additional insured" />
													</div>
													<div class="space-y-6 pt-4" v-if="unit.hasAdditionalInsured">
														<div class="w-full mb-4" :class="{errors: v$.additionalInsuredName.$error}">
															<Input name="additionalInsuredName" label="Name" @update-value="updateValue"/>
															<div v-for="error in v$.additionalInsuredName.$errors" class="error">{{ error.$message.replace('Value', 'Name') }}</div>
														</div>
														<div class="w-full mb-4" :class="{errors: v$.additionalInsuredAddress.$error}">
															<Input name="additionalInsuredAddress" label="Address" @update-value="updateValue" />
															<div v-for="error in v$.additionalInsuredAddress.$errors" class="error">{{ error.$message.replace('Value', 'Address') }}</div>
														</div>
														<div class="flex mb-4">
															<div class="w-1/2 pr-2" :class="{errors: v$.additionalInsuredCity.$error}">
																<Input name="additionalInsuredCity" label="Value" @update-value="updateValue" />
																<div v-for="error in v$.additionalInsuredCity.$errors" class="error">{{ error.$message.replace('Value', 'City') }}</div>
															</div>
															<div class="w-1/2 pl-2" :class="{errors: v$.additionalInsuredState.$error}">
																<Listbox label="State" name="additionalInsuredState" @update-value="updateValue" />
																<div v-for="error in v$.additionalInsuredState.$errors" class="error">{{ error.$message.replace('Value', 'State') }}</div>
															</div>
														</div>
														<div class="flex">
															<div class="w-1/2 pr-2" :class="{errors: v$.additionalInsuredZipCode.$error}">
																<Input name="additionalInsuredZipCode" label="Zip Code" @update-value="updateValue" />
																<div v-for="error in v$.additionalInsuredZipCode.$errors" class="error">{{ error.$message.replace('Value', 'Zip Code') }}</div>
															</div>
														</div>
													</div>
												</div>
											</fieldset>
											<fieldset class="divide-y divide-gray-400 px-4 sm:px-6">
												<div class="border-b border-gray-400 pb-8">
													<div class="w-full">
														<Toggle @update-value="updateValue" propname="lossPayee" label="Has a loss payee" />
													</div>
													<div class="space-y-6 pt-4" v-if="unit.lossPayee">
														<div class="w-full" v-if="unit.hasAdditionalInsured">
															<Toggle @update-value="updateValue" propname="lossPayeeSameAs" label="Loss payee is same as additional insured" />
														</div>
														<div v-if="!unit.lossPayeeSameAs">
															<div class="w-full mb-4" :class="{errors: v$.lossPayeeName.$error}">
																<Input name="lossPayeeName" label="Name" @update-value="updateValue" />
																<div v-for="error in v$.lossPayeeName.$errors" class="error">{{ error.$message.replace('Value', 'Name') }}</div>
															</div>
															<div class="w-full mb-4" :class="{errors: v$.lossPayeeAddress.$error}">
																<Input name="lossPayeeAddress" label="Address" @update-value="updateValue" />
																<div v-for="error in v$.lossPayeeAddress.$errors" class="error">{{ error.$message.replace('Value', 'Address') }}</div>
															</div>
															<div class="flex mb-4">
																<div class="w-1/2 pr-2" :class="{errors: v$.lossPayeeCity.$error}">
																	<Input name="lossPayeeCity" label="Value" @update-value="updateValue" />
																	<div v-for="error in v$.lossPayeeCity.$errors" class="error">{{ error.$message.replace('Value', 'City') }}</div>
																</div>
																<div class="w-1/2 pl-2" :class="{errors: v$.lossPayeeState.$error}">
																	<Listbox label="State" name="lossPayeeState" @update-value="updateValue" />
																	<div v-for="error in v$.lossPayeeState.$errors" class="error">{{ error.$message.replace('Value', 'State') }}</div>
																</div>
															</div>
															<div class="flex">
																<div class="w-1/2 pr-2" :class="{errors: v$.lossPayeeZipCode.$error}">
																	<Input name="lossPayeeZipCode" label="Zip Code" @update-value="updateValue" />
																	<div v-for="error in v$.lossPayeeZipCode.$errors" class="error">{{ error.$message.replace('Value', 'Zip Code') }}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</fieldset>
											<fieldset class="px-4 sm:px-6 py-8">
												<div class="w-full box">
													<FileUpload :maxSize="5" accept="pdf,png" />
												</div>
											</fieldset>
										</div>
									</div>
									<div class="flex flex-shrink-0 justify-end items-end px-4 py-4">
										<div class="mr-auto w-full pr-4" :class="{errors: v$.effectiveDate.$error}">
											<!-- <Date name="effectiveDate" label="Change Effective Date" :min="new Date(new Date().setDate(new Date().getDate() + 1))" :value="new Date(new Date().setDate(new Date().getDate() + 1))" @update-value="updateValue" /> -->
											<Date name="effectiveDate" label="Change Effective Date" @update-value="updateValue" />
											<div v-for="error in v$.effectiveDate.$errors" class="error">{{ error.$message }}</div>
										</div>
										<div class="flex">
											<button type="submit" class="btn mx-1" @click.prevent="validateUnit()">Request</button>
										</div>
									</div>
								</form>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { inject, computed, reactive } from 'vue'
	import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
	import LockedIcon from '@/icons/LockedIcon.vue'
	import { XMarkIcon } from '@heroicons/vue/24/outline'
	import Input from '@/components/inputs/Input.vue'
	import Date from '@/components/inputs/Date.vue'
	import InputVIN from '@/components/InputVIN.vue'
	import FileUpload from '@/components/inputs/FileUpload.vue'
	import Toggle from '@/components/inputs/Toggle.vue'
	import Listbox from '@/components/inputs/Select.vue'
	import CascadingSelect from '@/components/inputs/CascadingSelect.vue'
	import TextareaInput from '@/components/inputs/TextareaInput.vue'

	const store = inject('store')

	const unit = reactive({
		id: 0,
		unit: "",
		vin: "",
		year: "",
		model: "",
		vehicleType: "",
		specificType: "",
		make: "",
		value: "",
		cashValue: true,
		noPhysicalDamage: true,
		hasPhysDam: true,
		ownerOperator: "",
		ownerAddress: "",
		ownerCity: "",
		state: "",
		zip: "",
		driverName: "",
		garageLocation: "",
		radius: "",
		frequentlyTraveledCities: "",
		stateOfVehicleRegistration: "",
		hasLossPayee: false,
		lossPayeeName: "",
		lossPayeeAddress: "",
		lossPayeeCity: "",
		lossPayeeState: "",
		lossPayeeZipCode: "",
		hasAdditionalInsured: false,
		additionalInsuredName: "",
		additionalInsuredAddress: "",
		additionalInsuredCity: "",
		additionalInsuredState: "",
		additionalInsuredZipCode: "",
		effectiveDate: '',
		coverages: [],
		isChangePending: false
	})
	
	const { withAsync } = helpers

	// Validation Rules
	const addtInsuredRequired = (value, siblings) => {
		let isValid = true
		if(siblings.hasAdditionalInsured && !value) isValid = false
		return isValid
	}

	const lossPayeeRequired = (value, siblings) => {
		let isValid = true
		if(siblings.lossPayee && !value) isValid = false
		return isValid
	}

	const futureDate = (value) => {
		return value > store.state.currentDate
	}

	const rules = computed(() => {
		return {
			vin: { required },
			year: { required },
			make: { required },
			// model: { required },
			vehicleType: { required },
			// specificType: { required },
			value: { required },
			// cashValue: true,
			// noPhysicalDamage: true,
			// hasPhysDam: true,
			// ownerOperator: "",
			// ownerAddress: "",
			// ownerCity: "",
			// state: "",
			zip: { required },
			// driverName: "",
			// garageLocation: "",
			// radius: "",
			// frequentlyTraveledCities: "",
			stateOfVehicleRegistration: { required },
			additionalInsuredName: { addtInsuredRequired: helpers.withMessage('Name is required.', addtInsuredRequired) },
			additionalInsuredAddress: { addtInsuredRequired: helpers.withMessage('Address is required.', addtInsuredRequired) },
			additionalInsuredCity: { addtInsuredRequired: helpers.withMessage('City is required.', addtInsuredRequired) },
			additionalInsuredState: { addtInsuredRequired: helpers.withMessage('State is required.', addtInsuredRequired) },
			additionalInsuredZipCode: { addtInsuredRequired: helpers.withMessage('Zip Code is required.', addtInsuredRequired) },
			lossPayeeName: { lossPayeeRequired: helpers.withMessage('Name is required.', lossPayeeRequired) },
			lossPayeeAddress: { lossPayeeRequired: helpers.withMessage('Address is required.', lossPayeeRequired) },
			lossPayeeCity: { lossPayeeRequired: helpers.withMessage('City is required.', lossPayeeRequired) },
			lossPayeeState: { lossPayeeRequired: helpers.withMessage('State is required.', lossPayeeRequired) },
			lossPayeeZipCode: { lossPayeeRequired: helpers.withMessage('Zip Code is required.', lossPayeeRequired) },
			effectiveDate: { 
				required,
				futureDate: helpers.withMessage('The date must be in the future.', futureDate)
			}
		}
	})

	const v$ = useVuelidate(rules, unit)

	async function validateUnit(){
		const valid = await v$.value.$validate()
		if(valid) store.methods.addUnit(unit)
	}

	function updateValue(value, property) {
		// console.log(property, value)
		unit[property] = value._value ? value._rawValue : value
	}

	function VINAutofill(obj) {
		if(obj.make.length) unit.make = obj.make
		if(obj.model.length) unit.model = obj.model
		if(obj.year.length) unit.year = obj.year
	}

	function updateUnitType(type, subtype) {
		unit.vehicleType = type
		unit.specificType = subtype
	}

	function policyLineIsSelected(uniqLine) {
		return unit.coverages.includes(uniqLine)
	}

	// Update the policies added in the local driver variable.
	function updatePolicyLines(uniqLine) {
		// Check to see if the coverage exists and add or remove it accordingly.
		if(unit.coverages.includes(uniqLine)) {
			_.remove(unit.coverages, line => {
				return line === uniqLine
			})
		} else {
			unit.coverages.push(_.filter(store.state.unitCoverages, ['uniqLine', uniqLine])[0].uniqLine)
		}
	}	
</script>