<template><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="classList"><title>{{title}}</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" :stroke="color" stroke-miterlimit="10"><polyline points="2 14 8 14 8 17 16 17 16 14 22 14" stroke-linecap="butt"></polyline><rect x="2" y="2" width="20" height="20" rx="2"></rect><line x1="7" y1="6" x2="17" y2="6"></line><line x1="7" y1="10" x2="17" y2="10"></line></g></svg></template>

<script>
export default {
	props: {
        classList: {
            type: String
        },
        color: {
            type: String,
            default: "#1C2427"
        },
		title: {
			type: String,
			default: "Reported Claims"
		}
	}
}
</script>