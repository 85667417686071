<template><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="status-review"><title>{{title}}</title><g fill="#05d9ff"><path fill="#05d9ff" d="M8,14c3.6,0,6.4-3.1,7.6-4.9c0.5-0.7,0.5-1.6,0-2.3C14.4,5.1,11.6,2,8,2C4.4,2,1.6,5.1,0.4,6.9 c-0.5,0.7-0.5,1.6,0,2.2C1.6,10.9,4.4,14,8,14z M8,5c1.7,0,3,1.3,3,3s-1.3,3-3,3S5,9.7,5,8S6.3,5,8,5z"></path></g></svg></template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "In Review"
		},
        classList: {
            type: String,
            default: "hidden"
        }
	}
}
</script>