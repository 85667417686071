<template>
	<div>
		<div class="relative flex items-center">
            <div class="grow pr-4">
                <label :for="name" class="block text-sm text-gray-500 font-display font-bold uppercase absolute left-4 -top-1 px-2 bg-white" v-text="label"></label>
                <input @input="handleUpdate" :type="type" :name="name" :id="name" :maxlength="maxlength" class="block w-full rounded-md px-6 pt-5 pb-3 border-gray-300 shadow-sm focus:border-blue-900 focus:ring-blue-900 text-sm" />
            </div>
            <button class="btn ghost small text-blue-900" @click.prevent="vinLookup">Find VIN</button>
		</div>
	</div>
</template>
  
<script setup>
	const props = defineProps({
		value: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'text'
		},
		label: {
			type: String,
			required: false,
			default: undefined
		},
        required: {
            type: Boolean,
            required: false,
            default: false
        },
		name: {
			type: String,
			required: true,
			default: ''
		},
		maxlength: {
			type: String,
			required: false,
			default: '256'
		}
	})

	const emit = defineEmits({
		updateVin: null,
		updateValue: (s) => {
			if (typeof s === 'string') {
				return true
			} else {
				console.error(`Invalid submit event payload.`)
				return false
			}
		}        
	})

    let vin = String;

	const handleUpdate = (event) => {
		let value = event.target.value
        vin = value
		// console.log('updateValue', value, props.name)
		emit('updateValue', value, props.name)
	}

    function vinLookup() {
        const vin_data = {
            vin: vin,
            make: String,
            model: String,
            year: String
        };
        fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`)
        .then(response => response.json())
        .then(function(data){
            data.Results.forEach(element => {
                if(element.Variable === 'Make') vin_data.make = element.Value;
                if(element.Variable === 'Model') vin_data.model = element.Value;
                if(element.Variable === 'Model Year') vin_data.year = element.Value;
            });
            emit('updateVin', vin_data)
        });
    }
    


</script>